import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, 
  Box, Select,MenuItem,FormControl,InputLabel,TextField,Checkbox,FormControlLabel,Radio,RadioGroup,IconButton  } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import '../style.css'; // 스타일 파일 추가
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const TimeSlots = ({ date, events, onTimeSlotClick }) => {
  const timeSlots = [];
// 첫 시간 슬롯을 9:30으로 설정
timeSlots.push('9:30');
timeSlots.push('10:00');
timeSlots.push('10:30');
timeSlots.push('11:00');
timeSlots.push('11:30');
timeSlots.push('12:00');
timeSlots.push('13:30');
timeSlots.push('14:00');
timeSlots.push('14:30');
timeSlots.push('15:00');
timeSlots.push('15:30');
timeSlots.push('16:00');
timeSlots.push('16:30');
timeSlots.push('17:00');
timeSlots.push('17:30');



  const isTimeSlotReserved = (timeSlot) => {
    const [hour] = timeSlot.split(':');
    const slotStart = dayjs(date).hour(hour).minute(0).second(0).toDate();
    const slotEnd = dayjs(slotStart).add(29, 'minute').add(59, 'second').toDate();

    return events.some(event => {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);
      //console.log(eventEnd);
      return (slotStart >= eventStart && slotStart <= eventEnd) || (slotEnd >= eventStart && slotEnd <= eventEnd);
    });
  };

  const isTimeSlotPast = (timeSlot) => {
    const [hour] = timeSlot.split(':');
    const slotTime = dayjs(date).hour(hour).minute(0).second(0);
    return slotTime.isBefore(dayjs());
  };

  return (
    <Grid container spacing={2} sx={{ mt: 2 }}>
      {timeSlots.map((time) => (
        <Grid item key={time}>
          <Button
            variant="contained"
            onClick={() => onTimeSlotClick(time)}
            disabled={isTimeSlotReserved(time) || isTimeSlotPast(time)}
          >
            {time}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};



const ReservationForm = ({ open, handleClose, events,filterCartDataByDiviNum,data,handleSave,handleDelete }) => {

 // console.log(events);
  const initialDate = data && data.reserDate ? dayjs(data.reserDate) : dayjs();
  const initialTime = data && data.reserTime ? data.reserTime : null;

  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedTime, setSelectedTime] = useState(initialTime);
  const [formData, setFormData] = useState({
    ...data,
    reserDate: initialDate.format('YYYY-MM-DD'),
    reserTime: initialTime || '',
    selectSangdam: data?.selectSangdam || [], // Initialize with an empty array for multiple selections
    totalMoney:0,
    notMoney:0,
  });

  const jinState = filterCartDataByDiviNum(15); // 홈피 선택사항
  
  //console.log(jinState);


  /*
  useEffect(() => {
    if (selectedDate) {
      setFormData((prevData) => ({
        ...prevData,
        reserDate: dayjs(selectedDate).format('YYYY-MM-DD')
      }));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedTime) {
      setFormData((prevData) => ({
        ...prevData,
        reserTime: selectedTime
      }));
    }
  }, [selectedTime]);
   */

  const handlePrevMonth = () => {
    setSelectedDate(selectedDate.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedDate(selectedDate.add(1, 'month'));
  };



  useEffect(() => {
    if (data) {
      setFormData({
        ...data,
        reserDate: dayjs(data.reserDate).format('YYYY-MM-DD'),
        reserTime: data.reserTime ? data.reserTime : '',
        selectSangdam: data.selectSangdam || [],
        customerName: data.customerName || '',
        customerHp1: data.customerHp1 || '',
        customerEmail: data.customerEmail || '',
        totalMoney: data.totalMoney || 0,
        notMoney: data.notMoney || 0,
      });
      setSelectedDate(dayjs(data.reserDate));
      setSelectedTime(data.reserTime ? data.reserTime : '');
    }
  }, [data]);

  //console.log("data : " + formData.selectSangdam);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => {
      const updatedSelectSangdam = checked
        ? [...prevData.selectSangdam, parseInt(name)]
        : prevData.selectSangdam.filter((item) => item !== parseInt(name));
      return { ...prevData, selectSangdam: updatedSelectSangdam };
    });
  };


  const handleRadioChange = (event) => {
  const { value } = event.target;

  const selectedItem = jinState.find(item => item.gNum === parseInt(value));

  if (selectedItem) {
  setFormData((prevData) => ({
    ...prevData,
    selectSangdam: [parseInt(value)],  // 선택된 라디오 버튼의 값만 배열에 저장
    totalMoney:parseInt(selectedItem.moneywon),
    notMoney:parseInt(selectedItem.moneywon),
  }));
}
};
  
   const handleChange = (e) => {
    const { name, value } = e.target;
     if (name === 'customerHp1') {
      const cleanedValue = value.replace(/\D/g, '');
    const formattedPhone = formatPhoneNumber(cleanedValue);
      setFormData((prevData) => ({
        ...prevData,
        customerHp1: formattedPhone,
      }));
    }else{
    setFormData({ ...formData, [name]: value });
    }
  };


  
 const formatPhoneNumber = (phoneNumber) => {
  // 010-1234-5678 형태로 변환
  const match = phoneNumber.match(/^(\d{3})(\d{3,4})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  return phoneNumber;
};





  const handleTimeSlotClick = (time) => {
    setFormData((prevData) => ({
      ...prevData,
      reserTime: time
    }));
  };

  const shouldDisableDate = (date) => {
    return dayjs(date).isBefore(dayjs().startOf('day'));
  };


  const handleFormSave = () => {
    handleSave(formData);
  };

  const handleMonthChange = (newMonth) => {
    setSelectedDate(dayjs(newMonth));
  };

  const handleFormDel = () => {
    handleDelete(formData);
  };

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    setFormData((prevData) => ({
      ...prevData,
      reserDate: newDate.format('YYYY-MM-DD')
    }));
  };


    
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>예약하기</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
        <IconButton onClick={handlePrevMonth}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant="h5" align="center" gutterBottom>
          {selectedDate.format('YYYY-MM')}
        </Typography>
        <IconButton onClick={handleNextMonth}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

        <StaticDatePicker
         displayStaticWrapperAs="desktop"
            defaultValue={dayjs(initialDate.format('YYYY-MM-DD'))}
            value={selectedDate}
            //onChange={(newValue) => setFormData((prevData) => ({
            //  ...prevData,
            //  reserDate: dayjs(newValue).format('YYYY-MM-DD')
            //}))}

            onChange={handleDateChange}
            onMonthChange={handleMonthChange}

            //onMonthChange={handleMonthChange}
            shouldDisableDate={(date) => dayjs(date).isBefore(dayjs().startOf('day')) || dayjs(date).day() === 0  || dayjs(date).day() === 6 }
            sx={{
              '.MuiPickersToolbar-root': {
                display: 'none',
              },
              '.MuiPickersCalendarHeader-labelContainer': {
                display: 'none', // 기존의 월/연도 텍스트 숨기기
              },
              '.MuiPickersCalendarHeader-root': {
                      display: 'none', // 기존의 월/연도 전환 버튼 숨기기
                    },
            }}
          />
        </LocalizationProvider>
        {selectedDate && (
          <TimeSlots date={selectedDate} events={events} onTimeSlotClick={handleTimeSlotClick} />
        )}
        {formData.reserTime && (
          <Box sx={{ mt: 2 }}>
             <Box sx={{ mt: 1 }}>
          <Typography>선택한 날짜: {formData.reserDate}</Typography>
          <Typography>선택한 시간: {formData.reserTime}</Typography>
        </Box>
           <Typography variant="h6">서비스 선택</Typography>
  <RadioGroup
    value={formData.selectSangdam}
    onChange={handleRadioChange} // 이 핸들러는 라디오 버튼 값 변경에 사용됩니다.
  >
    {jinState.map((code) => (
      <FormControlLabel
        key={code.gNum}
        control={
          <Radio
            value={code.gNum.toString()}
            name="selectSangdam" // 모든 라디오 버튼이 같은 그룹에 속하도록 name을 동일하게 설정
          />
        }
        label={code.cartName}
      />
    ))}
  </RadioGroup>

            <TextField
              margin="dense"
              name="customerName"
              label="고객명"
              type="text"
              fullWidth
              value={formData.customerName || ''}
              onChange={handleChange}
              autoComplete="off"
      autoFocus
      inputProps={{ lang: 'ko' }}
              />
            
            
            <TextField
              margin="dense"
              name="customerHp1"
              label="핸드폰"
              fullWidth
              value={formData.customerHp1 || ''}
              onChange={handleChange}
            />

            <TextField
              margin="dense"
              name="customerEmail"
              label="이메일"
              type="text"
              fullWidth
              value={formData.customerEmail || ''}
              inputProps={{ inputMode: 'email', pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$' }} // 영어 입력 유도
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="totalMoney"
              label="총액"
              type="text"
              fullWidth
              value={formData.totalMoney || ''}
              onChange={handleChange}
            />

          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleFormDel} color="primary">
          삭제
        </Button>

        <Button onClick={handleClose} color="primary">
          취소
        </Button>
        <Button onClick={handleFormSave} color="primary">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ReservationForm;