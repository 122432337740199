import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const Basiccode1editform1 = ({ open, handleClose, data, handleSave, handleDelete }) => {
  const [formData, setFormData] = useState(data);
  const [confirmOpen, setConfirmOpen] = useState(false); // 삭제 확인 다이얼로그 상태


  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const saveChanges = () => {
    handleSave(formData);
  };

  const deleteRecord = () => {
    handleDelete(formData);
    setConfirmOpen(false); // 확인 다이얼로그 닫기
  };

  


  const isUpdate = !!formData.gNum;


  return (
    <Dialog open={open} onClose={handleClose}>
       <DialogTitle>{isUpdate ? '수정하기' : '신규 입력'}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="diviName"
          label="분류명"
          type="text"
          fullWidth
          value={formData.diviName}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          취소
        </Button>
         {isUpdate && (
          <Button onClick={() => setConfirmOpen(true)} color="error">
            삭제
          </Button>
        )}
        <Button onClick={saveChanges} color="primary">
           {isUpdate ? '저장' : '입력'}
        </Button>
      </DialogActions>

      {/* 삭제 확인 다이얼로그 */}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>정말 삭제하시겠습니까?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            취소
          </Button>
          <Button onClick={deleteRecord} color="error">
            삭제
          </Button>
        </DialogActions>
      </Dialog>


    </Dialog>
  );
};

export default Basiccode1editform1;
