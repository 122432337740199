import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';

const CustomTextField = ({ name, label, value, onChange,InputProps  }) => {
  const [formattedValue, setFormattedValue] = useState('');

  // 숫자에 천단위 콤마 적용 함수
  const formatNumber = (value) => {
    if (!value) return '';
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // 콤마를 제거한 숫자로만 변환하는 함수
  const parseNumber = (value) => {
    return value.toString().replace(/,/g, '');
  };

  // 외부에서 받은 값에 대해 천단위 콤마 적용
  useEffect(() => {
    if (value) {
      setFormattedValue(formatNumber(value));
    }else {
        setFormattedValue(''); // 값이 비어 있을 때 초기화
      }
  }, [value]);

  const handleInputChange = (event) => {
    const { value } = event.target;

    // 숫자만 필터링 후 상태에 반영
    const numericValue = parseNumber(value);
    setFormattedValue(formatNumber(numericValue));

    // 외부로 콜백을 통해 변경된 숫자 값을 전달
    onChange({
      target: {
        name,
        value: numericValue,
      },
    });
  };

  return (
    <TextField
      margin="dense"
      name={name}
      label={label}
      type="text"
      fullWidth
      value={formattedValue}
      onChange={handleInputChange}
      // InputProps가 있을 때만 전달
      {...(InputProps ? { InputProps } : {})}
    />
  );
};


const CustomDateField = ({ name, label, value, onChange }) => {
    const [formattedValue, setFormattedValue] = useState('');
  
    // 입력된 숫자를 YYYY-MM-DD 형식으로 변환하는 함수
    const formatDate = (value) => {
      if (!value) return '';
      const cleanedValue = value.toString().replace(/\D/g, ''); // 숫자 외의 문자는 제거
  
      if (cleanedValue.length <= 4) {
        return cleanedValue;
      } else if (cleanedValue.length <= 6) {
        return `${cleanedValue.toString().slice(0, 4)}-${cleanedValue.toString().slice(4, 6)}`;
      } else {
        return `${cleanedValue.toString().slice(0, 4)}-${cleanedValue.toString().slice(4, 6)}-${cleanedValue.toString().slice(6, 8)}`;
      }
    };
  
    // 날짜 형식을 위해 하이픈을 제거한 숫자만 반환하는 함수
    const parseDate = (value) => {
      return value.toString().replace(/-/g, '');
    };
  
    // 외부에서 받은 값을 포맷된 값으로 설정
    useEffect(() => {
      if (value) {
        setFormattedValue(formatDate(value));
      }else {
        setFormattedValue(''); // 값이 비어 있을 때 초기화
      }
    }, [value]);
  
    const handleInputChange = (event) => {
      const { value } = event.target;
  
      // 포맷팅된 날짜를 상태로 업데이트
      setFormattedValue(formatDate(value));
  
      // 숫자만 남긴 실제 값을 부모 컴포넌트로 전달
      onChange({
        target: {
          name,
          value: parseDate(value),
        },
      });
    };
  
    return (
      <TextField
        margin="dense"
        name={name}
        label={label}
        type="text"
        fullWidth
        value={formattedValue}
        onChange={handleInputChange}
        placeholder="YYYY-MM-DD"
      />
    );
  };



  export { CustomTextField, CustomDateField };
