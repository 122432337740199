import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const Basiccode2editform = ({ open, handleClose, data, handleSave, handleDelete, parentCategories }) => {
  const [formData, setFormData] = useState(data);
  const [confirmOpen, setConfirmOpen] = useState(false); // 삭제 확인 다이얼로그 상태

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNumberChange = (e) => {
    const { name, value } = e.target;
    // 숫자만 입력되도록 제한
    const numericValue = value.replace(/[^0-9]/g, '');
    setFormData({ ...formData, [name]: numericValue });
  };


  const saveChanges = () => {
    handleSave(formData);
  };

  const deleteRecord = () => {
    handleDelete(formData);
  };

  const isUpdate = !!formData.gNum;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{isUpdate ? '수정하기' : '신규 입력'}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="dense">
          <InputLabel>대분류</InputLabel>
          <Select
           label="대분류"
            name="diviNum"
            value={formData.diviNum || ''}
            onChange={handleChange}
            fullWidth
          >
            {parentCategories.map((category) => (
              <MenuItem key={category.gNum} value={category.gNum}>
                {category.diviName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          name="cartName"
          label="중분류명"
          type="text"
          fullWidth
          value={formData.cartName}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="sunWe"
          label="순위"
          type="text"
          fullWidth
          value={formData.sunWe}
          onChange={handleNumberChange}
        />
        <TextField
          margin="dense"
          name="moneywon"
          label="가격(해당할경우에만)"
          type="number"
          fullWidth
          value={formData.moneywon}
          onChange={handleNumberChange}
        />
        <TextField
          margin="dense"
          name="cartSul"
          label="설명"
          type="text"
          fullWidth
          multiline
          rows={4} // 기본적으로 4줄을 표시
          value={formData.cartSul}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          취소
        </Button>
        {isUpdate && (
          <Button onClick={() => setConfirmOpen(true)} color="error">
            삭제
          </Button>
        )}
        <Button onClick={saveChanges} color="primary">
          {isUpdate ? '저장' : '입력'}
        </Button>
      </DialogActions>

      {/* 삭제 확인 다이얼로그 */}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>정말 삭제하시겠습니까?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            취소
          </Button>
          <Button onClick={deleteRecord} color="error">
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default Basiccode2editform;
