import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid,Select,MenuItem,FormControl,InputLabel } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { CustomTextField, CustomDateField } from './CustomTextField';

const CompanyjusicForm = ({ open, handleClose, data, handleSave, handleDelete,filterCartDataByDiviNum,familyData,companyData,companyjusicData,handleEditCompanyjusic }) => {
 const [formData, setFormData] = useState(data || {});


 const jinState = filterCartDataByDiviNum(13); // 회사종류


  useEffect(() => {
    //console.log(data);
    if (!data) {
      // 새로 추가할 때 내가 선택해서 넘어온 회사를 선택
      const primaryMember = companyData.find(member => member.companygNum === member.data);
      setFormData(primaryMember => ({
        ...primaryMember,
        cCode: primaryMember.cDaeCode,
      }));
    } else {
      // 수정 시 기존 데이터를 그대로 사용
      setFormData(data);
     

      
    }
  }, [data, familyData,companyData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const saveChanges = () => {
    handleSave(formData);
  };

  const deleteChanges = () => {
    handleDelete(formData);
  };

  const isUpdate = !!formData.gNum;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isUpdate ? '주권발행법인 수정' : '주권발행법인 추가'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Select
                name="companygNum"
                value={formData.companygNum || ''}
                onChange={handleChange}
                fullWidth
              >
                {companyData.map((member) => (
                  <MenuItem key={member.gNum} value={member.gNum}>
                    {member.companyName}
                  </MenuItem>
                ))}
              </Select>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="jujuname"
              label="주주명"
              type="text"
              fullWidth
              value={formData.jujuname || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              margin="dense"
              name="jusicsu"
              label="주식수"
              type="number"
              fullWidth
              value={formData.jusicsu || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="reLation"
              label="특관여부(관계)"
              type="text"
              fullWidth
              value={formData.reLation || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="rateYu"
              label="비율"
              type="text"
              fullWidth
              value={formData.rateYu || ''}
              onChange={handleChange}
            />
          </Grid>
           <Grid item xs={12} sm={12}>
              <DataGrid
              rows={companyjusicData}
              columns={[
                { field: 'companyName', headerName: '주권발행법인', width: 150, editable: true },
                { field: 'jujuname', headerName: '주주명', width: 100, editable: true },
               { field: 'jusicsu', headerName: '주식수', width: 120, editable: true , valueFormatter: (params) => {
                if (params == null) {
                  return '';
                }
                if (params == "") {
                  return '';
                }
                return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
              },
              renderCell: (params) => (
                <div style={{ textAlign: 'right', width: '100%' }}>
                  {params.formattedValue}
                </div>
              )
            },
                { field: 'reLation', headerName: '특관여부(관계)', width: 100, editable: true },
                { field: 'rateYu', headerName: '비율', width: 120, editable: true },
              ]}
              pageSize={5}
              autoHeight
              getRowId={(row) => row.gNum}
              pagination={false}
                hideFooterPagination={true}
              hideFooter={true}
              onRowClick={(params) => handleEditCompanyjusic(params.row)}
              getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
            />
           </Grid>
         
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          취소
        </Button>
        {isUpdate && (
          <Button onClick={deleteChanges} color="primary">
            삭제
          </Button>
        )}
        <Button onClick={saveChanges} color="primary">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyjusicForm;
