import React, { useState, useEffect } from 'react';
import { Typography, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import api from '../api'; // Axios 인스턴스 가져오기
import Basiccode2editform from './Basiccode2editform';
import '../style.css'; // CSS 파일 임포트
const Basiccode2 = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [parentCategories, setParentCategories] = useState([]);
  const [selectedParent, setSelectedParent] = useState(0); // 초기값 0 설정

  const fetchParentCategories = async () => {
    try {
      const response = await api.post('/di/selectallAdivi'); // Adivi: 0으로 전체 대분류 가져오기
      setParentCategories(response.data);
    } catch (error) {
      console.error('Error fetching parent categories:', error);
    }
  };

  const fetchData = async (parentGNum) => {
    setLoading(true);
    try {
      const response = await api.post('/ca/selectallAcart', { Adivi: parentGNum });
      const rowsWithId = response.data.map((row, index) => ({ id: row.gNum || index, ...row }));
      setData(rowsWithId);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParentCategories();
  }, []);

  useEffect(() => {
    // 초기 진입 시 전체 데이터를 가져오기 위해 fetchData 호출
    fetchData(selectedParent);
  }, [selectedParent]);

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };

  const handleSave = async (updatedData) => {
    try {
      if (updatedData.gNum) {
        // 기존 데이터 수정
        await api.post('/ca/updateAcart', {
          ...updatedData,
          diviNum: updatedData.diviNum // 대분류의 고유번호를 함께 넘겨줌
        });
        setData((prevData) => prevData.map((row) => (row.gNum === updatedData.gNum ? updatedData : row)));
      } else {
        // 신규 데이터 입력
        const response = await api.post('/ca/insertAcart', {
          ...updatedData,
          diviNum: updatedData.diviNum // 대분류의 고유번호를 함께 넘겨줌
        });
        fetchData(selectedParent); // 전체 데이터를 다시 가져오기
      }
      handleClose();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleDelete = async (dataToDelete) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    try {
      await api.post('/ca/deleteAcart', dataToDelete);
      setData((prevData) => prevData.filter((row) => row.gNum !== dataToDelete.gNum));
      handleClose();
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleAddNew = () => {
    setSelectedRow({ gNum: '', diviName: '', diviNum: selectedParent });
    setDialogOpen(true);
  };

  const columns = [
    { field: 'gNum', headerName: '번호', width: 150 },
    { field: 'diviNum', headerName: '대분류코드', width: 100, hide: true },
    { field: 'diviName', headerName: '대분류명', width: 200 },
    { field: 'cartName', headerName: '중분류명', width: 300 },
    { field: 'sunWe', headerName: '순위', width: 100 },
    { field: 'moneywon', headerName: '금액', width: 60 },
    { field: 'cartSul', headerName: '설명', width: 300 },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ display: 'flex', alignItems: 'center', margin: '16px' }}>
        <Typography variant="h4" style={{ flexGrow: 1 }}>
          기초코드 관리
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddNew}
          style={{ marginLeft: '16px' }}
          disabled={selectedParent === 0} // 대분류 선택 안되어 있으면 비활성화
        >
          신규 입력
        </Button>
        <FormControl style={{ marginLeft: '16px', minWidth: '240px' }}>
          <InputLabel>기초분류 선택</InputLabel>
          <Select
            label="기초분류 선택"
            value={selectedParent}
            onChange={(e) => setSelectedParent(e.target.value)}
          >
            <MenuItem value={0}>전체</MenuItem>
            {parentCategories.map((category) => (
              <MenuItem key={category.gNum} value={category.gNum}>
                {category.diviName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ flexGrow: 1, margin: '0 16px 16px 16px' }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          loading={loading}
          getRowId={(row) => row.gNum}
          onRowClick={handleRowClick}
          style={{ height: '100%' }} // Adjust the height of the DataGrid
          getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
              }
        />
      </div>
      {selectedRow && (
        <Basiccode2editform
          open={dialogOpen}
          handleClose={handleClose}
          data={selectedRow}
          handleSave={handleSave}
          handleDelete={handleDelete}
          parentCategories={parentCategories}
        />
      )}
    </div>
  );
};

export default Basiccode2;
