import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid,Select,MenuItem,FormControl,InputLabel } from '@mui/material';
import { formatDate, formatBirthday, formatPhoneNumber } from './functions';
import { CustomTextField, CustomDateField } from './CustomTextField';
const ServiceForm = ({ open, handleClose, data, handleSave, handleDelete,filterCartDataByDiviNum,familyData }) => {
 const [formData, setFormData] = useState(data || {});


 const jinState = filterCartDataByDiviNum(14); // 진행사항


  useEffect(() => {
    if (!data) {
      // 새로 추가할 때 기본적으로 본인을 선택
      const primaryMember = familyData.find(member => member.cDaeCode === member.cCode);
      setFormData(primaryMember => ({
        ...primaryMember,
        cCode: primaryMember.cDaeCode,
      }));
    } else {
      // 수정 시 기존 데이터를 그대로 사용
     // console.log(data);
      setFormData(data);
    }
  }, [data, familyData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'serviceStartdate') {
      setFormData({ ...formData, [name]: formatDate(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    };


  const saveChanges = () => {
    handleSave(formData);
  };

  const deleteChanges = () => {
    handleDelete(formData);
  };

  const isUpdate = !!formData.gNum;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isUpdate ? '용역 수정' : '용역 추가'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Select
                name="cCode"
                value={formData.cCode || ''}
                onChange={handleChange}
                fullWidth
              >
                {familyData.map((member) => (
                  <MenuItem key={member.cCode} value={member.cCode}>
                    {member.cName}
                  </MenuItem>
                ))}
              </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="serviceTitle"
              label="과거용역진행"
              type="text"
              fullWidth
              value={formData.serviceTitle || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="serviceStartdate"
              label="용역시작일"
              type="text"
              fullWidth
              value={formData.serviceStartdate || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              margin="dense"
              name="serviceMoney"
              label="용역금액"
              type="number"
              fullWidth
              value={formData.serviceMoney || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <InputLabel>진행상태</InputLabel>
              <Select
               label="진행상태"
                name="serviceState"
                value={formData.serviceState || ''}
                onChange={handleChange}
                fullWidth
              >
                {
                  jinState.map((code) => (
                    <MenuItem key={code.cartName} value={code.cartName}>
                      {code.cartName}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>

           
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="gAb"
              label="갑"
              type="text"
              fullWidth
              value={formData.gAb || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="eUl"
              label="을"
              type="text"
              fullWidth
              value={formData.eUl || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="sMemo"
              label="메모"
              type="text"
              fullWidth
              value={formData.sMemo || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="linkAddress1"
              label="링크 주소 1"
              type="text"
              fullWidth
              value={formData.linkAddress1 || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="linkAddress2"
              label="링크 주소 2"
              type="text"
              fullWidth
              value={formData.linkAddress2 || ''}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          취소
        </Button>
        {isUpdate && (
          <Button onClick={deleteChanges} color="primary">
            삭제
          </Button>
        )}
        <Button onClick={saveChanges} color="primary">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceForm;
