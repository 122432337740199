import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField, Box,InputLabel,Select,MenuItem,
  Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions
 } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from '../api'; // Axios 인스턴스 가져오기
import ReservationModifyForm from './ReservationModifyForm';
import '../style.css'; // CSS 파일 임포트


function formatPaymentStateData(data) {
  const fieldDefinitions = {
    apikey: 'ERP 연동코드',
    bill_id: '청구서 ID',
    appr_cat_id: 'cat id',
    appr_pay_type: '결제수단',
    appr_dt: '승인일시',
    appr_origin_dt: '원거래 승인일시',
    appr_price: '승인금액',
    appr_issuer: '결제은행 / 카드명',
    appr_issuer_cd: '발행카드코드/은행코드',
    appr_issuer_num: '결제카드 / 계좌번호',
    appr_acquirer_cd: '매입사코드',
    appr_acquirer_nm: '매입사명',
    appr_num: '승인번호',
    appr_origin_num: '원거래승인번호',
    appr_monthly: '할부개월수',
    appr_state: '결제 상태',
  };

  return Object.keys(data)
    .map((key) => {
      const title = fieldDefinitions[key] || key; // 정의서에 없는 키는 그대로 사용
      const value = data[key] !== null ? data[key] : 'N/A'; // null인 경우 N/A로 표시
      return `${title}: ${value}`;
    })
    .join('\n'); // 한 줄에 하나씩 표시
}


function ReservationList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [cartData, setCartData] = useState([]);
  const [selectgNum,setSelectgNum] = useState(0);
  const [selectedData, setSelectedData] = useState(null); // State to hold the selected row data
  const [dateType, setDateType] = useState('regDate'); // Set initial value

   const [alertDialogOpen, setAlertDialogOpen] = useState(false); // Alert dialog 상태 추가
  const [alertDialogTitle, setAlertDialogTitle] = useState(''); // 다이얼로그 제목 상태
  const [alertDialogMessage, setAlertDialogMessage] = useState(''); // 다이얼로그 메시지 상태


 // 다이얼로그 핸들러
  const handleAlertDialogClose = () => {
    setAlertDialogOpen(false);
  };



  const fetchData = async (filters = {}) => {
    setLoading(true);
    try {
      const response = await api.post('/re/selectHomepagereser', filters);
      const rowsWithId = response.data.map((row, index) => ({ id: row.gNum || index, ...row }));
      setData(rowsWithId);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchCartData = async () => {
    try {
      const response = await api.post('/ca/selectallAcart', { Adivi: 15 });
      setCartData(response.data);
    } catch (error) {
      console.error('Error fetching cart data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchCartData();
  }, []);


  

   const sendpayment = async (updatedData) => {
    const confirmCancel = window.confirm('결제 요청을하시겠습니까?');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }
      try {
    const response = await api.post('/pm/paymentSend', { bill: updatedData });
      if (response.data && response.data.data.code !== '0000') {
        // 에러가 있을 경우
        setAlertDialogTitle('Error');
        setAlertDialogMessage(`${response.data.data.code}: ${response.data.data.msg}`);
      } else {
        // 성공일 경우
       

        setAlertDialogTitle('Success');
        setAlertDialogMessage(JSON.stringify(response.data.data, null, 2));
       
        updatedData.moneyCheck =  'W';
        await handleupdate(updatedData);

        // 고객 데이터를 다시 가져와 창을 업데이트
     //   await handleSave(updatedData);

    //  await fetchData(); // 전체 데이터를 다시 가져오는 경우
   //   await handleRowClick({ row: { gNum: updatedData.gNum } }); // 특정 고객 데이터를 다시 가져오는 경우
     

      }
      setAlertDialogOpen(true); // 다이얼로그를 열어 메시지 표시
    } catch (error) {
      console.error('Error fetching paymentSendState data:', error);
      setAlertDialogTitle('Error');
      setAlertDialogMessage(error.message);
      setAlertDialogOpen(true);
    }


  };


  const sendpaymentRe = async (updatedData) => {
    const confirmCancel = window.confirm('결제 요청을 재요청하시겠습니까?');

  if (!confirmCancel) {
    // 사용자가 "취소"를 선택한 경우 함수 실행 중단
    return;
  }

     try {
     const response = await api.post('/pm/paymentSendRe', { bill: updatedData });
      if (response.data && response.data.data.code !== '0000') {
        // 에러가 있을 경우
        setAlertDialogTitle('Error');
        setAlertDialogMessage(`${response.data.data.code}: ${response.data.data.msg}`);
      } else {
        // 성공일 경우
        setAlertDialogTitle('Success');
        setAlertDialogMessage(JSON.stringify(response.data.data, null, 2));
        //고객데이타를 수정을 보내고,
        updatedData.moneyCheck =  'W';
        await handleupdate(updatedData);
        // 고객 데이터를 다시 가져와 창을 업데이트
      await fetchData(); // 전체 데이터를 다시 가져오는 경우
      await handleRowClick({ row: { gNum: updatedData.gNum } }); // 특정 고객 데이터를 다시 가져오는 경우
       

      }
      setAlertDialogOpen(true); // 다이얼로그를 열어 메시지 표시
    } catch (error) {
      console.error('Error fetching paymentSendState data:', error);
      setAlertDialogTitle('Error');
      setAlertDialogMessage(error.message);
      setAlertDialogOpen(true);
    }



  };

   const sendpaymentCancle = async (updatedData) => {
    // 사용자에게 확인 메시지 표시
  const confirmCancel = window.confirm('정말로 취소하시겠습니까? 되돌릴수 없습니다');

  if (!confirmCancel) {
    // 사용자가 "취소"를 선택한 경우 함수 실행 중단
    return;
  }

        try {
      const response = await api.post('/pm/paymentSendCancle', { bill: updatedData });
      if (response.data && response.data.data.code !== '0000') {
        // 에러가 있을 경우
        setAlertDialogTitle('Error');
        setAlertDialogMessage(`${response.data.data.code}: ${response.data.data.msg}`);
      } else {
        // 성공일 경우
        setAlertDialogTitle('Success');
        setAlertDialogMessage(JSON.stringify(response.data.data, null, 2));
        updatedData.moneyCheck =  'C';
        updatedData.googleCheck =  '';
        await handleupdate(updatedData);
        // 고객 데이터를 다시 가져와 창을 업데이트
      await fetchData(); // 전체 데이터를 다시 가져오는 경우
      await handleRowClick({ row: { gNum: updatedData.gNum } }); // 특정 고객 데이터를 다시 가져오는 경우


      }
      setAlertDialogOpen(true); // 다이얼로그를 열어 메시지 표시
    } catch (error) {
      console.error('Error fetching paymentSendState data:', error);
      setAlertDialogTitle('Error');
      setAlertDialogMessage(error.message);
      setAlertDialogOpen(true);
    }


  };



   const sendpaymentDestroy = async (updatedData) => {
    const confirmCancel = window.confirm('정말로 파기하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

        try {
      const response = await api.post('/pm/paymentSendDestroy', { bill: updatedData });
      if (response.data && response.data.data.code !== '0000') {
        // 에러가 있을 경우
        setAlertDialogTitle('Error');
        setAlertDialogMessage(`${response.data.data.code}: ${response.data.data.msg}`);
      } else {
        // 성공일 경우
        setAlertDialogTitle('Success');
        setAlertDialogMessage(JSON.stringify(response.data.data, null, 2));
        updatedData.moneyCheck =  'D';
        await handleupdate(updatedData);
        // 고객 데이터를 다시 가져와 창을 업데이트
      await fetchData(); // 전체 데이터를 다시 가져오는 경우
      await handleRowClick({ row: { gNum: updatedData.gNum } }); // 특정 고객 데이터를 다시 가져오는 경우


      }
      setAlertDialogOpen(true); // 다이얼로그를 열어 메시지 표시
    } catch (error) {
      console.error('Error fetching paymentSendState data:', error);
      setAlertDialogTitle('Error');
      setAlertDialogMessage(error.message);
      setAlertDialogOpen(true);
    }


  };

  



   const sendpaymentState = async (updatedData) => {
    try {
      const response = await api.post('/pm/paymentSendState', { bill: updatedData });
      if (response.data && response.data.data.code) {
        // 에러가 있을 경우
        setAlertDialogTitle('Error');
        setAlertDialogMessage(`${response.data.data.code}: ${response.data.data.msg}`);
      } else {
        // 성공일 경우
        setAlertDialogTitle('Success');
        setAlertDialogMessage(formatPaymentStateData(response.data.data));
        // 고객 데이터를 다시 가져와 창을 업데이트
        updatedData.moneyCheck =  response.data.data.appr_state
        await handleupdate(updatedData);

      await fetchData(); // 전체 데이터를 다시 가져오는 경우
      await handleRowClick({ row: { gNum: updatedData.gNum } }); // 특정 고객 데이터를 다시 가져오는 경우

      }
      setAlertDialogOpen(true); // 다이얼로그를 열어 메시지 표시
    } catch (error) {
      console.error('Error fetching paymentSendState data:', error);
      setAlertDialogTitle('Error');
      setAlertDialogMessage(error.message);
      setAlertDialogOpen(true);
    }
  };



  



  const filterCartDataByDiviNum = (diviNum) => {
    return cartData.filter(cart => cart.diviNum === diviNum).sort((a, b) => a.sunWe - b.sunWe);
  };

  const handleRowClick = async (params) => {
    try {
      const response = await api.post('/re/getConsultationOne', { gNum: params.row.gNum });
      if (response.data) {
        setSelectedData(response.data); // Set the fetched data to selectedData
        setSelectgNum(params.row.gNum);
        setDialogOpen(true); // Open the dialog after data is fetched
      } else {
        console.error('No data returned from the server');
      }
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectgNum(null);
    setSelectedData(null); // Reset selected data
  };

  const handleSave = async (updatedData) => {
    try {

      if (updatedData.reserDate) {
        const date = new Date(updatedData.reserDate);
        updatedData.reserDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
      }


      if (updatedData.gNum) {
        // 기존 데이터 수정
        await api.post('/re/updateReser', updatedData);
        fetchData(); // 전체 데이터를 다시 가져오기
      } else {
        // 신규 데이터 입력
        await api.post('/re/insertReser', updatedData);
        fetchData(); // 전체 데이터를 다시 가져오기
      }
      handleClose();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleupdate = async (updatedData) => {
    try {

      await api.post('/re/updateReser', updatedData);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleDelete = async (dataToDelete) => {
    const confirmCancel = window.confirm('정말로 지우시겠습니까? ');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    try {
      const result = await api.post('/re/deleteReser', dataToDelete);
      
     // 서버로부터 결과를 받았을 때 처리
    if (result.data.Result === 'Y') {
      // 삭제 성공
      fetchData(); // 전체 데이터를 다시 가져오기
      handleClose();
    } else {
      // 삭제 실패 메시지 처리
      window.alert(result.data.Result); // 서버에서 받은 메시지를 알림으로 표시
    }
    } catch (error) {
      window.alert('삭제실패! 상담을 삭제전에 결제상태는 취소나 파기상태여야 합니다!');
      console.error('Error deleting data:', error);
    }
  };

  const handleAddNew = () => {
    setSelectgNum(1);
    setDialogOpen(true);
  };

  const handleSearch = () => {
    const filters = {
      searchName: searchText,
      dateType: dateType, // 예시: 가입일을 기준으로 검색
      startDate: startDate ? startDate.toISOString().split('T')[0] : null,
      endDate: endDate ? endDate.toISOString().split('T')[0] : null,
    };
    fetchData(filters);
  };

  const handleReset = () => {
    setSearchText('');
    setStartDate(null);
    setEndDate(null);
    setDateType('regDate');
    fetchData();
  };
  

  const handleDateTypeChange = (event) => {
    setDateType(event.target.value);
  };

   const columns = [
    { field: 'gNum', headerName: '번호', width: 100, hide: true },
    { field: 'customerName', headerName: '고객명', width: 150 },
    { field: 'customerHp1', headerName: '핸드폰', width: 150},
    { field: 'customerEmail', headerName: '이메일', width: 150 },
    {
      field: 'reserDate',
      headerName: '상담일',
      width: 150,
      valueGetter: (params) => {
          const dateStr = params;
          if (!dateStr) return ''; // reserDate가 없는 경우 빈 문자열 반환
  
          // 날짜 객체 생성
          const date = new Date(dateStr);
  
          // 날짜가 유효한지 확인
          if (isNaN(date.getTime())) {
              return ''; // 유효하지 않은 날짜인 경우 빈 문자열 반환
          }

          // 1900-01-01 체크
    if (date.getFullYear() === 1900 && date.getMonth() === 0 && date.getDate() === 1) {
      return ''; // 1900-01-01이면 빈 문자열 반환
    }
    
  
          // yyyy-MM-dd 형식으로 변환
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
      }
  },
    { field: 'reserTime', headerName: '상담시간', width: 150 },
    {
    field: 'moneyCheck',
    headerName: '입금',
    width: 90,
    valueGetter: (params) => {
      const value = params;
      switch (value) {
        case 'Y':
          return '입금';
        case 'F':
          return '입금';
        case 'C':
          return '취소';
        case 'D':
          return '파기';
        case 'W':
          return '입금대기';
        default:
          return '미발송';
      }
    }
  },
    { field: 'customerGnum', headerName: '고객번호', width: 100 },
    { field: 'regDate', headerName: '등록일', width: 180 },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '16px' }}>
        <TextField
          label="고객명"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="outlined"
          sx={{ marginRight: '16px' }}
        />
       
        <Select
          labelId="date-type-label"
          id="date-type-select"
          value={dateType}
          onChange={handleDateTypeChange}

        >
          <MenuItem value="regDate">등록일</MenuItem>
          <MenuItem value="reserDate">상담일</MenuItem>
        </Select>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="시작일"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} sx={{ marginRight: '16px' }} />}
            inputFormat="yyyy-MM-dd"
          />
          <DatePicker
            label="종료일"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} sx={{ marginRight: '16px' }} />}
            inputFormat="yyyy-MM-dd"
          />
        </LocalizationProvider>
        <Button variant="contained" color="primary" onClick={handleSearch} sx={{ marginRight: '16px' }} >
          검색
        </Button>
        <Button variant="contained" color="secondary" onClick={handleReset}>
          리셋
        </Button>
         
      </Box>
      <Box sx={{ flexGrow: 1, margin: '0 16px 16px 16px' }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={10}
          loading={loading}
          getRowId={(row) => row.gNum}
          onRowClick={handleRowClick}
          sx={{ height: '80vh' }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
          initialState={{
            columns: {
              columnVisibilityModel: {
                gNum: false,
                cDaeCode: false, // 숨기려는 열의 필드를 false로 설정
                cCode: false, // 숨기려는 열의 필드를 false로 설정
              },
            },
          }}
        />
      </Box>

       <Dialog
        open={alertDialogOpen}
        onClose={handleAlertDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{alertDialogTitle}</DialogTitle>
        <DialogContent>
          {alertDialogMessage.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>


      {selectgNum && (
        <ReservationModifyForm
          open={dialogOpen}
          handleClose={handleClose}
          handleSave={handleSave}
          handleDelete={handleDelete}
          filterCartDataByDiviNum={filterCartDataByDiviNum}
          gNum={selectgNum}
          initialData={selectedData} // Pass the selected row data to the form
          sendpayment={sendpayment}
          sendpaymentRe={sendpaymentRe}
          sendpaymentCancle={sendpaymentCancle}
          sendpaymentState={sendpaymentState}
          sendpaymentDestroy={sendpaymentDestroy}
        />
      )}

      
    </Box>
  );
}

export default ReservationList;
