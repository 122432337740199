import React, { useState,useEffect } from 'react';
import { Box, Button, TextField, List, ListItem, ListItemText, IconButton, Typography, ListItemSecondaryAction, Dialog, DialogContent, DialogActions
        ,CircularProgress,LinearProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import api from '../api'; 
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const MailsendForm = ({ open, onClose, selectedCustomers, setSelectedCustomers,cartselected }) => {
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (cartselected !== "0") {
      const fetchEmailTemplate = async () => {
        try {
            const response = await api.post('/ms/selectallAmailsendContents', { cartNum: cartselected });
          const { mailsendTitle, mailsendContents } = response.data[0];

          setSubject(mailsendTitle);
          setContent(mailsendContents);


          const response2 = await api.post('/cf/selectallAcartfilelistCopy', { cartgNum: cartselected });


          const copiedFiles = response2.data;

                setUploadedFiles(prevFiles => [
                    ...prevFiles,
                    ...copiedFiles.map((file, index) => ({
                        id: prevFiles.length + index + 1, // 자동 증가 ID
                        filename: file.filename, 
                        originalname: file.filename
                    }))
                ]);
        } catch (error) {
          //console.error('Error fetching email template:', error);
          alert('이메일 템플릿을 불러오는 중 오류가 발생했습니다.');
        }
      };

      fetchEmailTemplate();
    }
  }, [cartselected]);


  const handleRemoveCustomer = (email) => {
    setSelectedCustomers(prevCustomers =>
      prevCustomers.filter(customer => customer.email !== email)
    );
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    setIsUploading(true); // 파일 업로드 시작 시 업로드 상태를 true로 설정
    setLoading(true); // 파일 업로드 시작 시 로딩 상태 true로 설정
    try {
      const response = await api.post('/cf/insertmailfileupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted); // 프로그레스 업데이트
        }

      });

      if (response.status === 200) {
        //alert('파일이 성공적으로 업로드되었습니다.');
        setUploadedFiles(prevFiles => [
          ...prevFiles,
          { id: prevFiles.length + 1, filename: response.data.filename, originalname: file.name }
        ]);
        setUploadProgress(0); // 업로드 완료 후 초기화
        setLoading(false); // 파일 업로드가 완료되면 로딩 상태 false로 설정
      } else {
        alert('파일 업로드에 실패했습니다.');
      }

      
    
    } catch (error) {
      console.error('File upload failed:', error);
      alert('파일 업로드 중 오류가 발생했습니다.');
      setIsUploading(false); // 업로드가 끝나면 false로 설정
      setLoading(false); // 파일 업로드가 완료되면 로딩 상태 false로 설정
    } finally {
      setLoading(false); // 파일 업로드가 완료되면 로딩 상태 false로 설정
      setIsUploading(false); // 업로드가 끝나면 false로 설정
    }
  };

  const handleRemoveAttachment = (id) => {
    setUploadedFiles(prevFiles => prevFiles.filter(file => file.id !== id));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const companyjusicMember = {
      selectedCustomers,
      mailSubject: subject,
      mailContents: content,
      attachments: uploadedFiles.map(file => file.filename),
      sawonid: localStorage.getItem('msId'), // 로그인한 사원의 ID
    };

    try {
      const response = await api.post('/ct/mailsend', companyjusicMember);
      console.log('Email send response:', response.data);

      onClose(); // 폼 제출 후 다이얼로그 닫기
    } catch (error) {
      console.error('Error sending email:', error);
      alert('이메일 전송 중 오류가 발생했습니다.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth  PaperProps={{
      sx: {
        width: "100%",
        maxWidth: "1200px!important",
      },
    }}>
      <DialogContent>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography variant="subtitle1">선택된 고객 리스트[메일발송]</Typography>
          <List dense>
            {selectedCustomers.map(customer => (
              <ListItem key={customer.email}>
                <ListItemText primary={`${customer.name} (${customer.email})`} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveCustomer(customer.email)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>

          <TextField
            label="제목"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            variant="outlined"
            fullWidth
            required
          />

         
             <ReactQuill value={content} onChange={setContent} />
          <Typography variant="subtitle1">첨부파일</Typography>
          <Button startIcon={<AddIcon />} component="label"  disabled={loading}>
            첨부파일 추가
            <input type="file" hidden onChange={handleFileUpload} />
          </Button>
          {isUploading && (
        <div style={{ marginTop: 10 }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <p>{uploadProgress}%</p>
        </div>
      )}
          <List>
            {uploadedFiles.map((file) => (
              <ListItem key={file.id}>
                <ListItemText primary={file.originalname} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveAttachment(file.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          취소
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          메일 발송
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MailsendForm;
