import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Select, MenuItem,Grid,Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from '../api'; // Axios 인스턴스 가져오기
import moment from 'moment';
import clsx from 'clsx';


function Csmslog() {

    var today = new Date();
    var yesterday = new Date(today.valueOf() - (24*60*60*1000));
    var prev30day = new Date(today.valueOf() - (30*24*60*60*1000));
    var tomorrow = new Date(today.valueOf() + (24*60*60*1000));
    var nowdate = moment(today);


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateType, setDateType] = useState('regDate'); // Set initial value

  
  const [inputs, setInputs] = useState({
    bunru:'b',
    search_date: 'N',
    start_date: today.getFullYear() + '-' + ((today.getMonth() + 1) >=10 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1))  + '-' + (today.getDate() >=10 ? today.getDate() : '0' + today.getDate()),
    end_date:today.getFullYear() + '-' + ((today.getMonth() + 1) >=10 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1))  + '-' + (today.getDate() >=10 ? today.getDate() : '0' + today.getDate()),
    search_type1:'DEST_PHONE',
    search_namae:'',
    shopcode:"",
    pageno:'1',
    pagesize:'200000',
    gorun:false,
    sangtae:'998'
  });



  // 데이터 가져오기 함수
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.post('/sm/getRealSmsSendList', inputs);
      const rowsWithId = response.data.map((row, index) => ({ id: row.gNum || index, ...row }));
      setData(rowsWithId);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (inputs.gorun) {
      refetchSeach(); // 상태가 업데이트되고 나서 검색 실행
  
      // 검색 후 gorun을 다시 false로 설정
      setInputs(prevInputs => ({
        ...prevInputs,
        gorun: false, // 검색이 끝난 후 gorun을 false로 되돌림
      }));
    }
  }, [inputs]); // inputs 상태가 업데이트될 때마다 실행



  // 검색 핸들러
  const handleSearch = () => {
    const filters = {
      searchName: searchText,
      dateType: dateType, // 예시: 가입일을 기준으로 검색
      startDate: startDate ? startDate.toISOString().split('T')[0] : null,
      endDate: endDate ? endDate.toISOString().split('T')[0] : null,
    };
    fetchData(filters);
  };

  // 리셋 핸들러
  const handleReset = () => {
    setSearchText('');
    setStartDate(null);
    setEndDate(null);
    setDateType('regDate');
    fetchData();
  };

  // 날짜 타입 변경 핸들러
  const handleDateTypeChange = (event) => {
    setDateType(event.target.value);
  };

  const onChange = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setInputs({
      ...inputs, // 기존의 input 객체를 복사한 뒤
      [name]: value, // name 키를 가진 값을 value 로 설정,
      gorun:false,
    });
  };


  const refetchSeach = () =>{
    setInputs({
      ...inputs, // 기존의 input 객체를 복사한 뒤
      pageno:'1',
    })
  
    fetchData();
  }

  
  const onReset = () => {
    setInputs({
      ...inputs, // 기존의 input 객체를 복사한 뒤
      bunru:'b',
      search_date: 'N',
    start_date:  today.getFullYear() + '-' + ((today.getMonth() + 1) >=10 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1))  + '-' + (today.getDate() >=10 ? today.getDate() : '0' + today.getDate()),
    end_date:today.getFullYear() + '-' + ((today.getMonth() + 1) >=10 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1))  + '-' + (today.getDate() >=10 ? today.getDate() : '0' + today.getDate()),
    search_type1:'DEST_PHONE',
    search_namae:'',

    pageno:'1',
    pagesize:'200000',
    gorun:true,
    sangtae:'998'
    })

  };


  
  const yesterdaybae = () => {
    setInputs({
      ...inputs, // 기존의 input 객체를 복사한 뒤
      search_date: 'G',
    start_date: yesterday.getFullYear() + '-' + ((yesterday.getMonth() + 1) >=10 ? (yesterday.getMonth() + 1) : '0' + (yesterday.getMonth() + 1))  + '-' + (yesterday.getDate() >=10 ? yesterday.getDate() : '0' + yesterday.getDate()),
    end_date:yesterday.getFullYear() + '-' + ((yesterday.getMonth() + 1) >=10 ? (yesterday.getMonth() + 1) : '0' + (yesterday.getMonth() + 1))  + '-' + (yesterday.getDate() >=10 ? yesterday.getDate() : '0' + yesterday.getDate()),
    gorun:true,
    })
    //refetch();
   // refetchSeach(); // 날짜 변경 후 검색을 실행
    //setstart_date(yesterday.getFullYear() + '-' + ((yesterday.getMonth() + 1) >=10 ? (yesterday.getMonth() + 1) : '0' + (yesterday.getMonth() + 1))  + '-' + (yesterday.getDate() >=10 ? yesterday.getDate() : '0' + yesterday.getDate()));
    //setend_date(yesterday.getFullYear() + '-' + ((yesterday.getMonth() + 1) >=10 ? (yesterday.getMonth() + 1) : '0' + (yesterday.getMonth() + 1))  + '-' + (yesterday.getDate() >=10 ? yesterday.getDate() : '0' + yesterday.getDate()));
  };


  const todayin = () => {
    setInputs({
      ...inputs, // 기존의 input 객체를 복사한 뒤
    search_date: 'G',
    start_date: today.getFullYear() + '-' + ((today.getMonth() + 1) >=10 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1))  + '-' + (today.getDate() >=10 ? today.getDate() : '0' + today.getDate()),
    end_date:today.getFullYear() + '-' + ((today.getMonth() + 1) >=10 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1))  + '-' + (today.getDate() >=10 ? today.getDate() : '0' + today.getDate()),
    gorun:true,
    
    });
   // refetchSeach(); // 날짜 변경 후 검색을 실행

    //setstart_date(today.getFullYear() + '-' + ((today.getMonth() + 1) >=10 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1))  + '-' + (today.getDate() >=10 ? today.getDate() : '0' + today.getDate()));
    //setend_date(today.getFullYear() + '-' + ((today.getMonth() + 1) >=10 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1))  + '-' + (today.getDate() >=10 ? today.getDate() : '0' + today.getDate()));
    //refetch();
  };



  const {bunru,search_date, start_date,end_date,search_type1,search_namae,gorun,sangtae,shopcode } = inputs; // 비구조화 할당을 통해 값 추출


  function getFullName(params) {
    if (!params) {
      return '알수없음'; // row 또는 CALL_STATUS가 없을 때 기본값 반환
    }
    var resulta='';
    switch( params) {
       case '3200' :
           resulta ='FAX전달'
           break;
       case '3241' :
           resulta ='FAX부분전달'
           break;
       case '3211' :
           resulta ='FAX통화중'
           break;
       case '3212' :
           resulta ='FAX응답없음'
           break;
       case '3213' :
           resulta ='FAX잘못된번호'
           break;
       case '3214' :
           resulta ='FAX잘못된번호'
           break;
       case '3242' :
           resulta ='FAX사람받음'
           break;
       case '3244' :
           resulta ='FAX데이타불량'
           break;
       case '3245' :
           resulta ='FAX데이타없음'
           break;
       case '3221' :
           resulta ='FAX내부장애'
           break;
       case '4100' :
           resulta ='SMS완료'
           break;
        case '4400' :
           resulta ='음영지역'
           break;
         case '4410' :
           resulta ='번호오류'
           break;
         case '4420' :
           resulta ='기타오류'
           break;
         case '4430' :
           resulta ='수신거부'
           break;
         case '4421' :
           resulta ='타임아웃'
           break;
         case '4426' :
           resulta ='시도초과'
           break;
         case '4425' :
           resulta ='호처리중'
           break;
         case '4401' :
           resulta ='전원꺼짐'
           break;
         case '4402' :
           resulta ='저장초과'
           break;
         case '4422' :
           resulta ='일시정지'
           break;
         case '4427' :
           resulta ='단말문제'
           break;
         case '4423' :
           resulta ='착신거절'
           break;
         case '4414' :
           resulta ='결번'
           break;
         case '6600' :
           resulta ='LMS완료'
           break;
         case '6601' :
           resulta ='타임아웃'
           break;
        case '6603' :
           resulta ='음영지역'
           break;
         case '6604' :
           resulta ='전원꺼짐'
           break;
         case '6605' :
           resulta ='갯수초과'
           break;
         case '6606' :
           resulta ='번호오류'
           break;
         case '6607' :
           resulta ='일시정지'
           break;
         case '6608' :
           resulta ='단말문제'
           break;
        case '6617' :
           resulta ='통신스팸'
           break;
        case '6609' :
           resulta ='착신거절'
           break;
        case '6613' :
           resulta ='불가단말'
           break;
        case '6618' :
           resulta ='공정스팸'
           break;
        case '6620' :
           resulta ='건수초과'
           break;
        case '6621' :
           resulta ='길이초과'
           break;
        case '6622' :
           resulta ='번호형식'
           break;
        case '6623' :
           resulta ='DATA형식'
           break;
        case '4432' :
           resulta ='회신금지'
           break;
        case '6670' :
           resulta ='첨부용량'
           break;
        case '4433' :
           resulta ='회신금지'
           break;
        case '4434' :
            resulta ='회신미등록'
            break;
        case '9010' :
           resulta ='아이디틀림'
           break;
        case '9903' :
           resulta ='선불사용자 사용금지'
           break;
         case '9904' :
           resulta ='Block time (날짜제한)'
           break;
        case '9081' :
           resulta ='선불 사용자FAX, PHONE 발송 제한'
           break;
        case '9082' :
           resulta ='발송해제'
           break;
        case '9083' :
           resulta ='IP차단'
           break;
        case '9084' :
           resulta ='DEVICE 발송 제한'
           break;
        case '9085' :
           resulta ='사용금지 Callback 번호'
           break;
        case '9023' :
           resulta ='Callback error'
           break;
        case '9905' :
           resulta ='Block time'
           break;
        case '9011' :
           resulta ='비밀번호 틀림'
           break;
        case '9012' :
           resulta ='중복접속량 많음'
           break;
        case '9013' :
           resulta ='발송시간 지난 데이터'
           break;
        case '9014' :
           resulta ='알림톡/친구톡 유효하지 않은 발신프로필키'
           break;
        case '9015' :
           resulta ='알림톡/친구톡 발신프로필키 미입력'
           break;
        case '9016' :
           resulta ='알림톡/친구톡 템플릿 미입력'
           break;
        case '9017' :
           resulta ='존재하지 않는 첨부파일'
           break;
        case '9018' :
           resulta ='0바이트 첨부파일'
           break;
        case '9019' :
           resulta ='지원하지 않는 첨부파일'
           break;
        case '9020' :
           resulta ='Wrong Data Format"'
           break;
        case '9021' :
           resulta ='Wrong Data Format (no msg_body)'
           break;
        case '9022' :
           resulta ='Wrong Data Format (ex. cinfo가 특수 문자 / , 공백 을 포함)'
           break;
        case '9026' :
           resulta ='블랙리스트에 의한 차단'
           break;
        case '9027' :
           resulta ='MMS 첨부파일 이미지 사이즈 초과'
           break;
        case '9090' :
           resulta ='기타에러'
           break;
        case '7000' :
           resulta ='알림톡완료'
           break;
        case '7101' :
           resulta ='카카오 형식 오류'
           break;
        case '7103' :
           resulta ='Senderkey (발신프로필키) 유효하지 않음'
           break;
        case '7105' :
           resulta ='Senderkey (발신프로필키) 유효하지 않음'
           break;
         case '7106' :
           resulta ='삭제된 Senderkey (발신프로필키)'
           break;
         case '7107' :
           resulta ='차단 상태 Senderkey (발신프로필키)'
           break;
         case '7108' :
           resulta ='차단 상태 옐로우 아이디'
           break;
         case '7109' :
           resulta ='닫힌 상태 옐로우 아이디'
           break;
         case '7110' :
           resulta ='삭제된 옐로우 아이디'
           break;
         case '7203' :
           resulta ='친구톡 전송 시 친구대상 아님'
           break;
         case '7204' :
           resulta ='템플릿 불일치'
           break;
          case '7300' :
           resulta ='기타에러'
           break;
         case '7305' :
           resulta ='성공불확실(30일 이내 수신 가능)'
           break;
         case '7306' :
           resulta ='카카오 시스템 오류'
           break;
         case '7308' :
           resulta ='전화번호 오류'
           break;
        case '7311' :
           resulta ='메시지가 존재하지 않음'
           break;
         case '7314' :
           resulta ='메시지 길이 초과'
           break;
         case '7315' :
           resulta ='템플릿 없음'
           break;
         case '7318' :
           resulta ='메시지를 전송할 수 없음'
           break;
         case '7322' :
           resulta ='메시지 발송 불가 시간'
           break;
         case '7324' :
           resulta ='재전송 메시지 존재하지 않음'
           break;
           case '9034' :
            resulta ='발송유효초과'
            break;
      default:
           resulta = '알수없음';
           break;
    }
return `${resulta}`;
}

  
  const columns = [
    { field: 'id', headerName: '고유순번', width: 90, hide: true },
    { field: 'SEND_TIME', headerName: '날짜', width: 170, },
     { field: 'DEST_PHONE', headerName: '받는번호', width: 130 },
    { field: 'SEND_PHONE', headerName: '보낸번호', width: 130 },
    { field: 'MSG_BODY', headerName: '내용', width: 970,  },
    { field: 'CALL_STATUS', headerName: '상태', width: 100,valueGetter:getFullName , 
       cellClassName: (params) =>
      clsx('super-app', {
        gold: params.value === '알림톡완료',
        red:  params.value === 'LMS완료',
      })
  },

  ];
    
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '16px' }}>
      <Grid  container  spacing={1} >
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          문자발송내역
        </Typography>
      </Grid>
            <Grid  item xs={2}>
                         <TextField
    name="start_date" 
    id="date"
    label="시작일"
    type="date"
    defaultValue="2017-05-24"
    value={start_date}
    onChange={onChange}
    InputLabelProps={{
      shrink: true,
    }}
    fullWidth
     size="small"
  />
        </Grid>

                          <Grid  item xs={2}>
                         <TextField
    name="end_date" 
    id="date"
    label="종료일"
    type="date"
    defaultValue="2017-05-24"
    value={end_date}
    onChange={onChange}
    InputLabelProps={{
      shrink: true,
    }}
    fullWidth
    size="small"
  />
                         </Grid>

                         


                          <Grid  item xs={1.5}>
                         <TextField id="select" label="검색구분"  name="search_type1"  value={search_type1} onChange={onChange} select fullWidth size="small">
  <MenuItem value="DEST_PHONE">받는번호</MenuItem>
  <MenuItem value="SEND_PHONE">보내는번호</MenuItem>
  <MenuItem value="MSG_BODY">메세지내용</MenuItem>
  </TextField>
                         </Grid>


                         <Grid  item xs={1.5}>
                        <TextField id="outlined-basic" label="검색어입력" name="search_namae" value={search_namae} onChange={onChange} fullWidth size="small"
  onKeyDown={(e)=>{(e.key === "Enter") && refetchSeach();}}/>
                         </Grid>
 <Grid  item xs={5}>
                        <Button  variant="contained"   color="primary" onClick={refetchSeach}  size="small">검색하기</Button>
  &nbsp;
<Button  variant="contained"  size="small" color="primary" onClick={onReset}>검색초기화</Button> 
&nbsp;
<Button  variant="contained"  size="small" color="primary" onClick={todayin}>오늘발송</Button>
<Button  variant="contained"  size="small" color="primary" onClick={yesterdaybae}>어제발송</Button>
                         </Grid>

                       </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, margin: '0 16px 16px 16px' }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={10}
          loading={loading}

          sx={{ height: '80vh' }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
                cDaeCode: false,
                cCode: false,
              },
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default Csmslog;
