import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Login from './components/Login';
import Logout from './components/Logout';
import Basiccode1 from './components/Basiccode1';
import Basiccode2 from './components/Basiccode2';
import ServiceList from './components/ServiceList';
import EmployeeManagement from './components/EmployeeManagement';
import CustomerManagement2 from './components/Customer2';
import CustomerManagement from './components/Customer';
import Calendargoogle from './components/calendar';
import ReservationList from './components/reservationList';
import Mailfilelist from './components/Mailfilelist';
import MailContentsManager from './components/MailContentsManager';
import Csmslog from './components/Csmslog';
import { login, logout,checkAuth } from './features/login/loginSlice';
import { useSelector, useDispatch } from 'react-redux';
import theme from './theme';


const useVersionCheck = () => {
  const [isNewVersion, setIsNewVersion] = useState(false);

  const checkVersion = () => {
    fetch('/version.txt', { cache: 'no-store' })
      .then((response) => {
        if (!response.ok) {
          throw new Error('버전 파일이 없습니다.');
        }
        return response.text();
      })
      .then((serverVersion) => {
        const localVersion = localStorage.getItem('appVersion');
        if (localVersion !== serverVersion) {
          setIsNewVersion(true);
          localStorage.setItem('appVersion', serverVersion); // 버전 업데이트 저장
        }
      })
      .catch((error) => {
        console.warn('버전 파일을 불러올 수 없습니다:', error.message);
      });
  };

  useEffect(() => {
    // 페이지 처음 로드될 때 버전 체크
    checkVersion();

    // 페이지가 활성화될 때 버전 체크
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkVersion(); // 페이지가 활성화될 때만 버전 체크
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isNewVersion;
};

function App() {
  const isAuthenticated = useSelector((state) => state.login.isAuthenticated);
  const dispatch = useDispatch();

  const isNewVersion = useVersionCheck();


  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);



  return (
    <ThemeProvider theme={theme}>
    <Router>

       {/* 새로운 버전 알림 메시지, 모든 페이지에서 표시 */}
       {isNewVersion && (
          <div style={{ backgroundColor: 'yellow', padding: '10px', textAlign: 'center' }}>
            프로그램이 업데이트되었습니다(신규 버전). 페이지를 새로고침(f5) 해주세요.
          </div>
        )}


    {isAuthenticated && <Navbar />}
      <Routes>
        <Route
          path="/login"
          element={isAuthenticated ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path="/"
          element={isAuthenticated ? <ServiceList /> : <Navigate to="/login" />}
        />

        <Route
          path="/schedule"
          element={isAuthenticated ? <Calendargoogle /> : <Navigate to="/login" />}
        />
        


        <Route
          path="/basiccode1"
          element={isAuthenticated ? <Basiccode1 /> : <Navigate to="/login" />}
        />

        <Route
          path="/basiccode2"
          element={isAuthenticated ? <Basiccode2 /> : <Navigate to="/login" />}
        />

        <Route
          path="/sawon"
          element={isAuthenticated ? <EmployeeManagement /> : <Navigate to="/login" />}
        />

        <Route
          path="/reservation"
          element={isAuthenticated ? <ReservationList /> : <Navigate to="/login" />}
        />

        <Route
          path="/smslist"
          element={isAuthenticated ? <Csmslog /> : <Navigate to="/login" />}
        />



        <Route
          path="/customer"
          element={isAuthenticated ? <CustomerManagement2 /> : <Navigate to="/login" />}
        />


       <Route
          path="/mailfilelist"
          element={isAuthenticated ? <Mailfilelist /> : <Navigate to="/login" />}
        />

        <Route
          path="/MailContentsManager"
          element={isAuthenticated ? <MailContentsManager /> : <Navigate to="/login" />}
        />





        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/logout" element={<Logout />} />

        
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
