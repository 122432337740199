// src/Navbar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
  };

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            BOSS1.0(보스원)
          </Typography>
          <Button color="inherit" onClick={() => handleNavigation('/')}>
            진행용역
          </Button>
          &nbsp;&nbsp;
          <Button color="inherit" onClick={() => handleNavigation('/customer')}>
            고객
          </Button>
          &nbsp;&nbsp;
           <Button color="inherit" onClick={() => handleNavigation('/reservation')}>
            상담신청
          </Button>
          &nbsp;&nbsp;
          <Button color="inherit" onClick={() => handleNavigation('/schedule')}>
            일정
          </Button>
          &nbsp;&nbsp;
          <Button color="inherit" onClick={() => handleNavigation('/smslist')}>
            문자발송내역
          </Button>
          &nbsp;&nbsp;
          <Button color="inherit" onClick={handleMenuClick}>
            환경설정
          </Button>
          &nbsp;&nbsp;
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => handleNavigation('/basiccode1')}>기초분류</MenuItem>
            <MenuItem onClick={() => handleNavigation('/basiccode2')}>기초코드</MenuItem>
            <MenuItem onClick={() => handleNavigation('/mailfilelist')}>파일관리</MenuItem>
            <MenuItem onClick={() => handleNavigation('/MailContentsManager')}>메일내용관리</MenuItem>
            
            <MenuItem onClick={() => handleNavigation('/sawon')}>사원관리</MenuItem>
          </Menu>
          <Button color="inherit" onClick={() => handleNavigation('/logout')}>
            로그아웃
          </Button>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
