import React from 'react';

function About() {
  return (
    <div>
      <h1>고객</h1>
      <p>고객리스트</p>
    </div>
  );
}

export default About;