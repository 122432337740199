import React, { useState, useEffect,useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Select, FormControl, InputLabel, Grid, Typography
  ,TableContainer,Table,TableHead,TableRow,TableCell,FormControlLabel,Checkbox,TableBody,Chip
 } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { formatDate, formatBirthday, formatPhoneNumber } from './functions';
import FamilyMemberForm from './FamilyMemberForm'; // 가족 구성원 추가/수정 폼 컴포넌트
import ServiceForm from './ServiceForm'; // 용역 추가/수정 폼 컴포넌트
import YugapaperForm from './YugapaperForm'; //유가증권
import { IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import api from '../api'; // Axios 인스턴스 가져오기
import '../style.css'; // CSS 파일 임포트
import userCustomerData2 from './useCustoomerData2';
import MoneyForm from './MoneyForm';
import BudongsanForm from './BudongsaForm';
import CaemuForm from './CaemuForm';
import CompanyForm from './CompanyForm';
import CompanyjusicForm from './CompanyjusinForm';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MailsendForm from './MailsendForm';
import SmsForm from './SmsForm';
import moment from "moment";


const CustomerModifyForm2 = ({ 
  open, handleClose, handleSave, handleDelete,filterCartDataByDiviNum,gNum,selectedCustomersms,setSelectedCustomersms
  ,selectedCustomers,setSelectedCustomers,cartselected,mailDialogOpen,handleMailDialogClose,setMailDialogOpen
  }) => {
  
  const {data, 
    familyData, 
    handleFamilyUpdate,
    serviceData,
    handleServiceUpdate,
    handleMoneyUpdate,
    moneyData, 
    yugaPaperData, 
    handleYugaUpdate,
    budongsanData, 
    handleBudongsanUpdate,
    caemuData, 
    handleCaemuUpdate,
    companyData, 
    handleCompanyUpdate,
    companyjusicData,
    handleCompanyjusicUpdate,
    loading,
    handleReset,fetchCustomerData,
    emailsendData,
    handleEmailsendDataUpdate,
    
  } = userCustomerData2(gNum);


  const [formData, setFormData] = useState(data || {});
  const [confirmOpen, setConfirmOpen] = useState(false); // 삭제 확인 다이얼로그 상태
  const [selectedFamilyMember, setSelectedFamilyMember] = useState(null);
  const [familyDialogOpen, setFamilyDialogOpen] = useState(false);
  const [familyMembers, setFamilyMembers] = useState(familyData || []);
  const [selectedService, setSelectedService] = useState(null);
  const [serviceDialogOpen, setServiceDialogOpen] = useState(false);
  const [serviceMembers, setServiceMembers] = useState(serviceData || []);
  const [moneyMembers, setMoneyMembers] = useState(moneyData || []);
  const [moneyDialogOpen, setMoneyDialogOpen] = useState(false);
  const [selectedMoney, setSelectedMoney] = useState(null);
  const [yugaMembers, setYugaMembers] = useState(yugaPaperData || []);
  const [yugaDialogOpen, setYugaDialogOpen] = useState(false);
  const [selectedYuga, setSelectedYuga] = useState(null);
  const [budongsanMembers, setBudongsanMembers] = useState(budongsanData || []);
  const [budongsanDialogOpen, setBudongsanDialogOpen] = useState(false);
  const [selectedBudongsan, setSelectedBudongsan] = useState(null);
  const [caemuMembers, setCaemuMembers] = useState(caemuData || []);
  const [caemuDialogOpen, setCaemuDialogOpen] = useState(false);
  const [selectedCaemu, setSelectedCaemu] = useState(null);
  const [companyMembers, setCompanyMembers] = useState(companyData || []);
  const [companyDialogOpen, setCompanyDialogOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyjusicMembers, setCompanyjusicMembers] = useState(companyjusicData || []);
  const [companyjusicDialogOpen, setCompanyjusicDialogOpen] = useState(false);
  const [selectedCompanyjusic, setSelectedCompanyjusic] = useState(null);
  const [content, setContent] = useState({content :'',gNum:''});
  const [emailsendDatas, setEmailsendDatas] = useState(emailsendData || []);
  const [ovviewyn, setOvviewyn] = useState({
    jinview1:true,
    jinview2:true,
    jinview3:true,
    jinview4:true,
    jinview5:true,
  });

  const [smsSend, setSmsSend] = useState({
    adminid: localStorage.getItem('msId'),
    msid: "01062292523",
    opensmsform: false,
    send_number: "",
    receive_number: { hpnumber: "", rname: "" },
    send_text: "",
    send_subject: "",
    send_type: "",
    customer_code: "",
    customer_name: "",
    order_code: "",
    reservationYn:"N",
    reservation_date:moment().format("YYYY-MM-DD"),
    reservation_time:moment().format("HH:mm:ss"),
  });



  const genderCodes = filterCartDataByDiviNum(6); // 성별의 Adivi 값 사용
  const gradeCodes = filterCartDataByDiviNum(1); // 고객등급의 Adivi 값 사용
  const jasaCodes = filterCartDataByDiviNum(5); // 고객등급의 Adivi 값 사용
  const editSectionRef = useRef(null); // 수정 창을 참조하는 useRef 생성

  useEffect(() => {
    if (data) {
      setFormData(data);
      setFamilyMembers(familyData);
      setServiceMembers(serviceData);
      setYugaMembers(yugaPaperData);
      setBudongsanMembers(budongsanData);
      setCaemuMembers(caemuData);
      setCompanyMembers(companyData);
      setCompanyjusicMembers(companyjusicData);
      setEmailsendDatas(emailsendData);
    }
  }, [data, familyData, serviceData,yugaPaperData,budongsanData,caemuData,companyData,companyjusicData,emailsendData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
   
    if (name === 'cHp1') {
      setFormData({ ...formData, [name]: formatPhoneNumber(value) });
    }else if (name === 'cjuMin1') {
      setFormData({ ...formData, [name]: formatBirthday(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }

  };

  const saveChanges = () => {
    handleSave({ ...formData, family: familyMembers });
  };

  const deleteRecord = () => {
    handleDelete(formData);
  };

  const thishandleClose = () => {
    handleReset();
    setFormData({});
    setSelectedFamilyMember(null);
    setSelectedService(null);
    setSelectedMoney(null);
    setSelectedYuga(null);
    setSelectedBudongsan(null);
    setSelectedCaemu(null);
    setSelectedCompany(null);
    setSelectedCompanyjusic(null);
    setEmailsendDatas(null);
    handleClose();
  };

 


  const isUpdate = !!formData.gNum;

  const handleAddFamilyMember = () => {
    setSelectedFamilyMember({ cDaeCode: formData.cDaeCode }); // 대표코드를 설정
    setFamilyDialogOpen(true);
  };

  const handleEditFamilyMember = (familyMember) => {
    setSelectedFamilyMember(familyMember);
    setFamilyDialogOpen(true);
  };

 const handleSaveFamilyMember = async (familyMember) => {
    try {
      let response;
      if (familyMember.gNum) {
        // 수정된 구성원
        response = await api.post('/ct/updateAcustomer', familyMember);
        setFamilyMembers(familyMembers.map((member) => (member.gNum === familyMember.gNum ? familyMember : member)));
        handleFamilyUpdate(familyMembers.map((member) => (member.gNum === familyMember.gNum ? familyMember : member)));
      } else {
        // 새 구성원 추가
        response = await api.post('/ct/insertAcustomer', familyMember);
        //setFamilyMembers([...familyMembers, { ...familyMember, gNum: response.data.gNum }]);
        const newFamilyMember = { ...familyMember, gNum: response.data.recordset[0].gNum, id: response.data.recordset[0].gNum };
      setFamilyMembers([...familyMembers, newFamilyMember]);
      handleFamilyUpdate([...familyMembers, newFamilyMember]);
       
      }
      fetchCustomerData(gNum);
      setFamilyDialogOpen(false);
    } catch (error) {
      console.error('Error saving family member:', error);
    }
  };
  const handleDeleteFamilyMember = async (familyMember) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    try {
      await api.post('/ct/deleteAcustomer', familyMember); // 동일한 백엔드 엔드포인트 사용
      setFamilyMembers(familyMembers.filter((member) => member.gNum !== familyMember.gNum));
      handleFamilyUpdate(familyMembers.filter((member) => member.gNum !== familyMember.gNum));
      fetchCustomerData(gNum);
      setFamilyDialogOpen(false);
    } catch (error) {
      console.error('Error deleting family member:', error);
    }
  };


  const handleFamilyFormClose = () => {
    setSelectedFamilyMember(null);
    setFamilyDialogOpen(false);
    
  };





    const handleAddService = () => {
    setSelectedService({ cCode: formData.cDaeCode }); // 대표코드를 설정
    setServiceDialogOpen(true);
  };

  const handleEditService = (familyMember) => {
    setSelectedService(familyMember);
    setServiceDialogOpen(true);
  };

  const handleSaveService = async (serviceMember) => {
    try {
      let response;
      if (serviceMember.gNum) {
        // 수정된 구성원
        response = await api.post('/se/updateAservice', serviceMember);
        setServiceMembers(serviceMembers.map((member) => (member.gNum === serviceMember.gNum ? serviceMember : member)));
        handleServiceUpdate(serviceMembers.map(s => s.gNum === serviceMember.gNum ? serviceMember : s));
      } else {
        // 새 구성원 추가
        response = await api.post('/se/insertAservice', serviceMember);
        const newServiceMember = { ...serviceMember, gNum: response.data.gNum };
            setServiceMembers((prevServiceMembers) => [...prevServiceMembers, newServiceMember]);
            handleServiceUpdate((prevServiceMembers) => [...prevServiceMembers, newServiceMember]);
      }
      fetchCustomerData(gNum);
      setServiceDialogOpen(false);
    } catch (error) {
      console.error('Error saving family member:', error);
    }
  };

  const handleDeleteService = async (serviceMember) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    try {
      await api.post('/se/deleteAservice', serviceMember); // 동일한 백엔드 엔드포인트 사용
      setServiceMembers(serviceMembers.filter((member) => member.gNum !== serviceMember.gNum));
      handleServiceUpdate(serviceMembers.filter((member) => member.gNum !== serviceMember.gNum));
      fetchCustomerData(gNum);
      setServiceDialogOpen(false);
    } catch (error) {
      console.error('Error deleting family member:', error);
    }
  };

  const handleServiceFormClose = () => {
    setSelectedService(null);
    setServiceDialogOpen(false);
    
  };




  const handleAddMoney = () => {
    setSelectedMoney({ cCode: formData.cDaeCode }); // 대표코드를 설정
    setMoneyDialogOpen(true);
  };

  const handleEditMoney = (familyMember) => {
    setSelectedMoney(familyMember);
    setMoneyDialogOpen(true);
  };

  const handleSaveMoney = async (moneyMember) => {
    try {
      let response;
      if (moneyMember.gNum) {
        // 수정된 구성원
        response = await api.post('/cm/updateAcustomermoney', moneyMember);
        setMoneyMembers(moneyMembers.map((member) => (member.gNum === moneyMember.gNum ? moneyMember : member)));
        handleMoneyUpdate(moneyMembers.map(s => s.gNum === moneyMember.gNum ? moneyMember : s));
      } else {
        // 새 구성원 추가
        response = await api.post('/cm/insertAcustomermoney', moneyMember);
        const newMoneyMember = { ...moneyMember, gNum: response.data.gNum };
            setMoneyMembers((prevMoneyMembers) => [...prevMoneyMembers, newMoneyMember]);
            handleMoneyUpdate((prevMoneyMembers) => [...prevMoneyMembers, newMoneyMember]);
      }
      fetchCustomerData(gNum);
      setMoneyDialogOpen(false);
    } catch (error) {
      console.error('Error saving family member:', error);
    }
  };

  const handleDeleteMoney = async (moneyMember) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    try {
      await api.post('/cm/deleteAcustomermoney', moneyMember); // 동일한 백엔드 엔드포인트 사용
      setMoneyMembers(moneyMembers.filter((member) => member.gNum !== moneyMember.gNum));
      handleMoneyUpdate(moneyMembers.filter((member) => member.gNum !== moneyMember.gNum));
      fetchCustomerData(gNum);
      setMoneyDialogOpen(false);
    } catch (error) {
      console.error('Error deleting money member:', error);
    }
  };

  const handleMoneyFormClose = () => {
    setSelectedMoney(null);
    setMoneyDialogOpen(false);
    
  };



  
  const handleAddYuga = () => {
    setSelectedYuga({ cCode: formData.cDaeCode }); // 대표코드를 설정
    setYugaDialogOpen(true);
  };

  const handleEditYuga = (familyMember) => {
    setSelectedYuga(familyMember);
    setYugaDialogOpen(true);
  };

  const handleSaveYuga = async (yugaMember) => {
    try {
      let response;
      if (yugaMember.gNum) {
        // 수정된 구성원
        response = await api.post('/yg/updateYuga', yugaMember);
        setYugaMembers(yugaMembers.map((member) => (member.gNum === yugaMember.gNum ? yugaMember : member)));
        handleYugaUpdate(yugaMembers.map(s => s.gNum === yugaMember.gNum ? yugaMember : s));
      } else {
        // 새 구성원 추가
        response = await api.post('/yg/insertYuga', yugaMember);
        const newYugaMember = { ...yugaMember, gNum: response.data.gNum };
            setYugaMembers((prevYugaMembers) => [...prevYugaMembers, newYugaMember]);
            handleYugaUpdate((prevYugaMembers) => [...prevYugaMembers, newYugaMember]);
      }
      fetchCustomerData(gNum);
      setYugaDialogOpen(false);
    } catch (error) {
      console.error('Error saving family member:', error);
    }
  };

  const handleDeleteYuga = async (yugaMember) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    try {
      await api.post('/yg/deleteYuga', yugaMember); // 동일한 백엔드 엔드포인트 사용
      setYugaMembers(yugaMembers.filter((member) => member.gNum !== yugaMember.gNum));
      handleYugaUpdate(yugaMembers.filter((member) => member.gNum !== yugaMember.gNum));
      fetchCustomerData(gNum);
      setYugaDialogOpen(false);
    } catch (error) {
      console.error('Error deleting Yuga member:', error);
    }
  };

  const handleYugaFormClose = () => {
    setSelectedYuga(null);
    setYugaDialogOpen(false);
    
  };



  const handleAddCaemu = () => {
    setSelectedCaemu({ cCode: formData.cDaeCode }); // 대표코드를 설정
    setCaemuDialogOpen(true);
  };

  const handleEditCaemu = (familyMember) => {
    setSelectedCaemu(familyMember);
    setCaemuDialogOpen(true);
  };
  
  const handleSaveCaemu = async (caemuMember) => {
    try {
      let response;
      if (caemuMember.gNum) {
        // 수정된 구성원
        response = await api.post('/ch/updateCaemu', caemuMember);
        setCaemuMembers(caemuMembers.map((member) => (member.gNum === caemuMember.gNum ? caemuMember : member)));
        handleCaemuUpdate(caemuMembers.map(s => s.gNum === caemuMember.gNum ? caemuMember : s));
      } else {
        // 새 구성원 추가
        response = await api.post('/ch/insertCaemu', caemuMember);
        const newCaemuMemberMember = { ...caemuMember, gNum: response.data.gNum };
            setCaemuMembers((prevCaemuMembers) => [...prevCaemuMembers, newCaemuMemberMember]);
            handleCaemuUpdate((prevCaemuMembers) => [...prevCaemuMembers, newCaemuMemberMember]);
      }
      fetchCustomerData(gNum);
      setCaemuDialogOpen(false);
    } catch (error) {
      console.error('Error saving family member:', error);
    }
  };

  const handleDeleteCaemu = async (caemuMember) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    try {
      await api.post('/ch/deleteCaemu', caemuMember); // 동일한 백엔드 엔드포인트 사용
      setCaemuMembers(caemuMembers.filter((member) => member.gNum !== caemuMember.gNum));
      handleCaemuUpdate(caemuMembers.filter((member) => member.gNum !== caemuMember.gNum));
      fetchCustomerData(gNum);
      setCaemuDialogOpen(false);
    } catch (error) {
      console.error('Error deleting caemuMember member:', error);
    }
  };

  const handleCaemuFormClose = () => {
    setSelectedCaemu(null);
    setCaemuDialogOpen(false);
    
  };



  
  const handleAddBudongsan = () => {
    setSelectedBudongsan({ cCode: formData.cDaeCode }); // 대표코드를 설정
    setBudongsanDialogOpen(true);
  };

  const handleEditBudongsan = (familyMember) => {
    setSelectedBudongsan(familyMember);
    setBudongsanDialogOpen(true);
  };
  
  const handleSaveBudongsan = async (budongsanMember) => {
    try {
      let response;
      if (budongsanMember.gNum) {
        // 수정된 구성원
        response = await api.post('/cb/updateAcustomerbudongsan', budongsanMember);
        setBudongsanMembers(budongsanMembers.map((member) => (member.gNum === budongsanMember.gNum ? budongsanMember : member)));
        handleBudongsanUpdate(budongsanMembers.map(s => s.gNum === budongsanMember.gNum ? budongsanMember : s));
      } else {
        // 새 구성원 추가
        response = await api.post('/cb/insertAcustomerbudongsan', budongsanMember);
        const newBudongsanMemberMember = { ...budongsanMember, gNum: response.data.gNum };
            setBudongsanMembers((prevBudongsanMembers) => [...prevBudongsanMembers, newBudongsanMemberMember]);
            handleBudongsanUpdate((prevBudongsanMembers) => [...prevBudongsanMembers, newBudongsanMemberMember]);
      }
      fetchCustomerData(gNum);
      setBudongsanDialogOpen(false);
    } catch (error) {
      console.error('Error saving family member:', error);
    }
  };

  const handleDeleteBudongsan = async (budongsanMember) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    try {
      await api.post('/cb/deleteAcustomerbudongsan', budongsanMember); // 동일한 백엔드 엔드포인트 사용
      setBudongsanMembers(budongsanMembers.filter((member) => member.gNum !== budongsanMember.gNum));
      handleBudongsanUpdate(budongsanMembers.filter((member) => member.gNum !== budongsanMember.gNum));
      fetchCustomerData(gNum);
      setBudongsanDialogOpen(false);
    } catch (error) {
      console.error('Error deleting budongsanMember member:', error);
    }
  };

  const handleBudongsanFormClose = () => {
    setSelectedBudongsan(null);
    setBudongsanDialogOpen(false);
    
  };




  const handleAddCompany = () => {
    setSelectedCompany({ cCode: formData.cDaeCode }); // 대표코드를 설정
    setCompanyDialogOpen(true);
  };

  const handleEditCompany = (familyMember) => {
    setSelectedCompany(familyMember);
    setCompanyDialogOpen(true);
  };


  const regSangdam = async ()=>{
      //상담내용에 대해서 서버로 보냅니다.
      
      if (content.gNum.toString().length > 0){
        const result = await api.post('/ct/sangdamModify', {basicinfo : formData,msg : content.content,sawonid:localStorage.getItem('msId'),gNum:content.gNum}); // 동일한 백엔드 엔드포인트 사용   
      }else{
     const result = await api.post('/ct/sangdamSave', {basicinfo : formData,msg : content.content,sawonid:localStorage.getItem('msId')}); // 동일한 백엔드 엔드포인트 사용
      }
     setContent({content : '',gNum:''});
     fetchCustomerData(gNum);
  }


  const handleDeleteRecord = async (sangdamnum) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? ');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    const result = await api.post('/ct/sangdamDel', {sangdamnum:sangdamnum}); // 동일한 백엔드 엔드포인트 사용
    fetchCustomerData(gNum);


  }

  let rows;
  if (emailsendDatas) {
    rows = emailsendDatas.filter(function (element) {
      return (
        (ovviewyn.jinview1 && element["readYn"] === 'S') || 
        (ovviewyn.jinview2 && element["readYn"] === 'N') || 
        (ovviewyn.jinview3 && element["readYn"] === 'M')
      );
    });
  }


  function onJINmodify(modifydata) {
    setContent({
      content: modifydata.mailContents,
      gNum: modifydata.gNum
    });

    // 수정 창으로 스크롤 이동
    if (editSectionRef.current) {
      editSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

  }


  const handleAddMailonelick = ()=>{
    //console.log("data");
    //console.log(data);
   
  
  const selectedCustomerData =[{ id: formData.cCode,
    name: formData.cName,
    email: formData.cEmail1,}]
   // console.log(selectedCustomerData);
    setSelectedCustomers(selectedCustomerData);
    setMailDialogOpen(true);

    
  }




  
  const handleSaveCompany = async (companyMember) => {
    try {
      let response;
      if (companyMember.gNum) {
        // 수정된 구성원
        response = await api.post('/cp/updateCompany', companyMember);
        setCompanyMembers(companyMembers.map((member) => (member.gNum === companyMember.gNum ? companyMember : member)));
        handleCompanyUpdate(companyMembers.map(s => s.gNum === companyMember.gNum ? companyMember : s));
      } else {
        // 새 구성원 추가
        response = await api.post('/cp/insertCompany', companyMember);
        const newCompanyMember = { ...companyMember, gNum: response.data.gNum };
            setCompanyMembers((prevCompanyMembers) => [...prevCompanyMembers, newCompanyMember]);
            handleCompanyUpdate((prevCompanyMembers) => [...prevCompanyMembers, newCompanyMember]);
      }
      fetchCustomerData(gNum);
      setCompanyDialogOpen(false);
    } catch (error) {
      console.error('Error saving family member:', error);
    }
  };

  const handleDeleteCompany = async (companyMember) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    try {
      await api.post('/cp/deleteCompany', companyMember); // 동일한 백엔드 엔드포인트 사용
      setCompanyMembers(companyMembers.filter((member) => member.gNum !== companyMember.gNum));
      handleCompanyUpdate(companyMembers.filter((member) => member.gNum !== companyMember.gNum));
      fetchCustomerData(gNum);
      setCompanyDialogOpen(false);
    } catch (error) {
      console.error('Error deleting Company member:', error);
    }
  };

  const handleCompanyFormClose = () => {
    setSelectedCompany(null);
    setCompanyDialogOpen(false);
    
  };




  const handleAddCompanyjusic = (companydata) => {
    setSelectedCompanyjusic({ companygNum: companydata.gNum }); // 대표코드를 설정
    setCompanyjusicDialogOpen(true);
  };

  const handleEditCompanyjusic = (companyMember) => {
    setSelectedCompanyjusic(companyMember);
    setCompanyjusicDialogOpen(true);
  };
  
  const handleSaveCompanyjusic = async (companyjusicMember) => {
    try {
      let response;
      if (companyjusicMember.gNum) {
        // 수정된 구성원
        response = await api.post('/cj/updateComjusic', companyjusicMember);
        setCompanyjusicMembers(companyjusicMembers.map((member) => (member.gNum === companyjusicMember.gNum ? companyjusicMember : member)));
        handleCompanyjusicUpdate(companyjusicMembers.map(s => s.gNum === companyjusicMember.gNum ? companyjusicMember : s));
      } else {
        // 새 구성원 추가
        response = await api.post('/cj/insertComjusic', companyjusicMember);
        const newCompanyjusicMember = { ...companyjusicMember, gNum: response.data.gNum };
            setCompanyjusicMembers((prevCompanyjusicMembers) => [...prevCompanyjusicMembers, newCompanyjusicMember]);
            handleCompanyjusicUpdate((prevCompanyjusicMembers) => [...prevCompanyjusicMembers, newCompanyjusicMember]);
      }
      fetchCustomerData(gNum);
      setCompanyjusicDialogOpen(false);
    } catch (error) {
      console.error('Error saving family member:', error);
    }
  };

  const handleDeleteCompanyjusic = async (companyjusicMember) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }
    
    try {
      await api.post('/cj/deleteComjusic', companyjusicMember); // 동일한 백엔드 엔드포인트 사용
      setCompanyjusicMembers(companyjusicMembers.filter((member) => member.gNum !== companyjusicMember.gNum));
      handleCompanyjusicUpdate(companyjusicMembers.filter((member) => member.gNum !== companyjusicMember.gNum));
      fetchCustomerData(gNum);
      setCompanyjusicDialogOpen(false);
    } catch (error) {
      console.error('Error deleting Companyjusic member:', error);
    }
  };

  const handleCompanyjusicFormClose = () => {
    setSelectedCompanyjusic(null);
    setCompanyjusicDialogOpen(false);
    
  };




const handleRowClick = (params) => {
  if (params.field === 'companyName') {
    // 특정 칸 (예: companyName) 클릭 시 다른 작업 수행
    handleAddCompanyjusic(params.row);
  } else {
    // 그 외의 경우에는 기존 동작 수행
    handleEditCompany(params.row);
  }
};
  

  const handleCellClick = (params, event) => {
    const { field, value } = params;
    if ((field === 'linkAddress1' || field === 'linkAddress2') && value) {
      event.stopPropagation();
      window.open(value, '_blank');
    }
  };


  const toggleview1 = () => {
    setOvviewyn({
     ...ovviewyn, // 기존의 input 객체를 복사한 뒤
     jinview1: ovviewyn.jinview1 ? false:true,
   });
 }

  const toggleview2 = () => {
    setOvviewyn({
     ...ovviewyn, // 기존의 input 객체를 복사한 뒤
     jinview2: ovviewyn.jinview2 ? false:true,
   });
 }

  const toggleview3 = () => {
    setOvviewyn({
     ...ovviewyn, // 기존의 input 객체를 복사한 뒤
     jinview3: ovviewyn.jinview3 ? false:true,
   });
 }

 const handleAddSMS = () => {
  setSmsSend({
    ...smsSend, // 기존의 input 객체를 복사한 뒤
    adminid: localStorage.getItem('msId'),
    opensmsform: true,
    send_number: "01062292523",
    receive_number: [{id:1,hpnumber:formData.cHp1}],
    customer_code: [formData.cCode],
    customer_name: [formData.cName],
  });
};



  return (
    <Dialog open={open} onClose={thishandleClose}  fullWidth  PaperProps={{
      sx: {
        width: "100%",
        maxWidth: "1600px!important",
      },
    }}>
      <DialogTitle>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={1.5}>
            <TextField
              margin="dense"
              name="cName"
              label="고객명"
              type="text"
              fullWidth
              value={formData.cName || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <FormControl fullWidth margin="dense">
              <InputLabel>고객등급</InputLabel>
              <Select
              label="고객등급"
                name="cLevel"
                value={formData.cLevel || ''}
                onChange={handleChange}
                fullWidth
              >
                {gradeCodes.map((code) => (
                  <MenuItem key={code.cartName} value={code.cartName}>
                    {code.cartName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <TextField
              margin="dense"
              name="cHp1"
              label="핸드폰번호"
              type="text"
              fullWidth
              value={formData.cHp1 || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <TextField
              margin="dense"
              name="cEmail1"
              label="이메일"
              type="email"
              fullWidth
              value={formData.cEmail1 || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <TextField
              margin="dense"
              name="cjuMin1"
              label="생년월일"
              type="text"
              fullWidth
              value={formData.cjuMin1 || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            <FormControl fullWidth margin="dense">
              <InputLabel>성별</InputLabel>
              <Select
                label="성별"
                name="cjuMin2"
                value={formData.cjuMin2 || ''}
                onChange={handleChange}
                fullWidth
              >
                {genderCodes.map((code) => (
                  <MenuItem key={code.cartName} value={code.cartName}>
                    {code.cartName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
             <FormControl fullWidth margin="dense">
              <InputLabel>보유자산</InputLabel>
             <Select
             label="보유자산"
                name="cJaesan"
                value={formData.cJaesan || ''}
                onChange={handleChange}
                fullWidth
              >
                {jasaCodes.map((code) => (
                  <MenuItem key={code.cartName} value={code.cartName}>
                    {code.cartName}
                  </MenuItem>
                ))}
              </Select>
              </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
          <FormControl fullWidth margin="dense">
          <Button variant="outlined" onClick={handleAddSMS} color="primary" size='large'>
          문자
        </Button> 
        </FormControl>
            </Grid>
           

          
        </Grid>
        
      </DialogTitle>
      <DialogContent>
      <Grid container spacing={1} > 
      <Grid item xs={12} sm={7}>



        <Grid container>
          <Grid item xs={12} sm={12}>
             <Typography variant="h7" gutterBottom sx={{
    fontWeight: 'bold', // 글자를 두껍게
    color: '#315df9', // 색상을 파란색으로 설정 (원하는 색상으로 변경 가능)
  }}>
              용역진행사항
              <IconButton
    color="secondary"
    size="small"
    onClick={handleAddService}
    style={{ marginLeft: '10px', color: 'red' }} // 빨간색 설정
  >
    <AddIcon />
    </IconButton>
            </Typography>
            <DataGrid
              rows={serviceData}
              columns={[
                { field: 'serviceStartdate', headerName: '용역시작일', width: 110, editable: true },
                { field: 'serviceTitle', headerName: '과거용역진행', width: 150, editable: true },
                { 
                  field: 'serviceMoney', 
                  headerName: '수수료', 
                  width: 80, 
                  valueFormatter: (params) => {
                    if (params == null) {
                      return '';
                    }
                    return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  },
                  renderCell: (params) => (
                    <div style={{ textAlign: 'right', width: '100%' }}>
                      {params.formattedValue}
                    </div>
                  )
                },

                { field: 'serviceState', headerName: '진행상태', width: 70, editable: true },
                { field: 'gAb', headerName: '갑', width: 100, editable: true },
                { field: 'eUl', headerName: '을', width: 100, editable: true },
                { field: 'sMemo', headerName: '특이사항', width: 140, editable: true },
                { field: 'linkAddress1', headerName: '관령링크1', width: 80, editable: true },
                { field: 'linkAddress2', headerName: '관령링크2', width: 80, editable: true },
              ]}
              pageSize={15}
              autoHeight
              getRowId={(row) => row.gNum}
              hideFooterPagination={true}
              hideFooter={true}
              loading={loading}
              onCellClick={handleCellClick} 
              onRowClick={(params) => handleEditService(params.row)}
              getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
            />
          </Grid>

          <Grid item xs={12} sm={12} sx={{
    fontWeight: 'bold', // 글자를 두껍게
    color: '#315df9', // 색상을 파란색으로 설정 (원하는 색상으로 변경 가능)
  }}   style={{ marginTop: '16px' }}>
             <Typography variant="h7" gutterBottom>
              가족관계
              <IconButton
    color="secondary"
    size="small"
    onClick={handleAddFamilyMember}
    style={{ marginLeft: '10px', color: 'red' }} // 빨간색 설정
  >
    <AddIcon />
    </IconButton>
            </Typography>
            <DataGrid
              rows={familyData}
              columns={[
                { field: 'cName', headerName: '이름', width: 150, editable: true },
                { field: 'cDaeRelation', headerName: '관계', width: 150, editable: true },
                { field: 'cjuMin1', headerName: '생년월일', width: 100, editable: true },
                { field: 'cjuMin2', headerName: '성별', width: 70, editable: true },
                { field: 'localYn', headerName: '거주구분', width: 120, editable: true },
                { field: 'foreginYn', headerName: '내외국민', width: 120, editable: true },
                { field: 'meMo1', headerName: '메모', width: 170, editable: true },
              ]}
              pageSize={15}
              autoHeight
              getRowId={(row) => row.gNum}
              hideFooterPagination={true}
              hideFooter={true}
              loading={loading}
              onRowClick={(params) => handleEditFamilyMember(params.row)}
              getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
            />
          </Grid>
          <Grid item xs={12} sm={12}  style={{ marginTop: '16px' }}>
            <Typography variant="h7" gutterBottom sx={{
    fontWeight: 'bold', // 글자를 두껍게
    color: '#315df9', // 색상을 파란색으로 설정 (원하는 색상으로 변경 가능)
  }}>
              금융자산
              <IconButton
    color="secondary"
    size="small"
    onClick={handleAddMoney}
    style={{ marginLeft: '10px', color: 'red' }} // 빨간색 설정
  >
    <AddIcon />
    </IconButton>

            </Typography>
            <DataGrid
              rows={moneyData}
              columns={[
                { field: 'cName', headerName: '소유자', width: 120, editable: true },
                { field: 'cartName', headerName: '종류', width: 80, editable: true },
                { field: 'changeDate', headerName: '변동일', width: 100, editable: true },
                { field: 'plusMoney', headerName: '변동금액(증)', width: 120, editable: true 
                  , valueFormatter: (params) => {
                    if (params == null) {
                      return '';
                    }
                    return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  },
                  renderCell: (params) => (
                    <div style={{ textAlign: 'right', width: '100%' }}>
                      {params.formattedValue}
                    </div>
                  )
                },
                { field: 'minusMoney', headerName: '변동금액(감)', width: 120, editable: true 
                  , valueFormatter: (params) => {
                    if (params == null) {
                      return '';
                    }
                    return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  },
                  renderCell: (params) => (
                    <div style={{ textAlign: 'right', width: '100%' }}>
                      {params.formattedValue}
                    </div>
                  )
                },
                { field: 'saYu', headerName: '사유', width: 200, editable: true },
                { field: 'sangDaebang', headerName: '상대방', width: 100, editable: true },
              ]}
              pageSize={15}
              autoHeight
              getRowId={(row) => row.gNum}
              pagination={false}
               hideFooterPagination={true}
              hideFooter={true}
              onRowClick={(params) => handleEditMoney(params.row)}
              getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '16px' }}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h7" gutterBottom sx={{
    fontWeight: 'bold', // 글자를 두껍게
    color: '#315df9', // 색상을 파란색으로 설정 (원하는 색상으로 변경 가능)
  }}>유가증권
              <IconButton
    color="secondary"
    size="small"
    onClick={handleAddYuga}
    style={{ marginLeft: '10px', color: 'red' }} // 빨간색 설정
  >
    <AddIcon />
    </IconButton>
            </Typography>
            <DataGrid
              rows={yugaPaperData}
              columns={[
                { field: 'cName', headerName: '소유자', width: 120, editable: true },
                { field: 'jongRu', headerName: '종류', width: 80, editable: true },
                { field: 'changeDate', headerName: '변동일', width: 100, editable: true },
                 { field: 'plusSu', headerName: '변동수량(증)', width: 110, editable: true , valueFormatter: (params) => {
                    if (params == null) {
                      return '';
                    }
                    if (params == "") {
                      return '';
                    }
                    return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+'주';
                  },
                  renderCell: (params) => (
                    <div style={{ textAlign: 'right', width: '100%' }}>
                      {params.formattedValue}
                    </div>
                  )
                },
                { field: 'minusSu', headerName: '변동수량(감)', width: 110, editable: true , valueFormatter: (params) => {
                  if (params == null) {
                    return '';
                  }
                  if (params == "") {
                    return '';
                  }
                  return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')+'주';
                },
                renderCell: (params) => (
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    {params.formattedValue}
                  </div>
                )
              },
                { field: 'saYu', headerName: '사유', width: 150, editable: true },
                { field: 'companyName', headerName: '기업명', width: 100, editable: true },
                { field: 'onejudangPrice', headerName: '1주당가액', width: 100, editable: true , valueFormatter: (params) => {
                  if (params == null) {
                    return '';
                  }
                  if (params == "") {
                    return '';
                  }
                  return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
                renderCell: (params) => (
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    {params.formattedValue}
                  </div>
                )
              },
              ]}
              pageSize={5}
              autoHeight
              getRowId={(row) => row.gNum}
              pagination={false}
               hideFooterPagination={true}
               onRowClick={(params) => handleEditYuga(params.row)}
              hideFooter={true}
              getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
            />
          </Grid>
          <Grid item xs={12} sm={12}  style={{ marginTop: '16px' }}>
            <Typography variant="h7" gutterBottom sx={{
    fontWeight: 'bold', // 글자를 두껍게
    color: '#315df9', // 색상을 파란색으로 설정 (원하는 색상으로 변경 가능)
  }}>부동산
              <IconButton
    color="secondary"
    size="small"
    onClick={handleAddBudongsan}
    style={{ marginLeft: '10px', color: 'red' }} // 빨간색 설정
  >
    <AddIcon />
    </IconButton>

            </Typography>
            <DataGrid
              rows={budongsanData}
              columns={[
               { field: 'cName', headerName: '소유자', width: 120, editable: true },
                { field: 'jongRu', headerName: '종류', width: 80, editable: true },
                { field: 'changeDate', headerName: '변동일', width: 100, editable: true },
                { field: 'plusSu', headerName: '변동금액(증)', width: 120, editable: true , valueFormatter: (params) => {
                  if (params == null) {
                    return '';
                  }
                  return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
                renderCell: (params) => (
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    {params.formattedValue}
                  </div>
                )
              },
                { field: 'minusSu', headerName: '변동금액(감)', width: 120, editable: true , valueFormatter: (params) => {
                  if (params == null) {
                    return '';
                  }
                  return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
                renderCell: (params) => (
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    {params.formattedValue}
                  </div>
                )
              },
                { field: 'saYu', headerName: '사유', width: 150, editable: true },
                { field: 'addRess', headerName: '주소지', width: 100, editable: true },
                { field: 'sangDaebang', headerName: '상대방', width: 100, editable: true },
              ]}
              pageSize={5}
              autoHeight
              getRowId={(row) => row.gNum}
              pagination={false}
               hideFooterPagination={true}
              hideFooter={true}
              onRowClick={(params) => handleEditBudongsan(params.row)}
              getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '16px' }}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h7" gutterBottom sx={{
    fontWeight: 'bold', // 글자를 두껍게
    color: '#315df9', // 색상을 파란색으로 설정 (원하는 색상으로 변경 가능)
  }}>채무
             <IconButton
    color="secondary"
    size="small"
    onClick={handleAddCaemu}
    style={{ marginLeft: '10px', color: 'red' }} // 빨간색 설정
  >
    <AddIcon />
    </IconButton>
            </Typography>
            <DataGrid
              rows={caemuData}
              columns={[
                 { field: 'cName', headerName: '소유자', width: 120, editable: true },
                { field: 'jongRu', headerName: '종류', width: 80, editable: true },
                { field: 'changeDate', headerName: '변동일', width: 100, editable: true },
                { field: 'plusSu', headerName: '변동금액(증)', width: 120, editable: true , valueFormatter: (params) => {
                  if (params == null) {
                    return '';
                  }
                  return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
                renderCell: (params) => (
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    {params.formattedValue}
                  </div>
                )
              },
                { field: 'minusSu', headerName: '변동금액(감)', width: 120, editable: true , valueFormatter: (params) => {
                  if (params == null) {
                    return '';
                  }
                  return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
                renderCell: (params) => (
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    {params.formattedValue}
                  </div>
                )
              },
                { field: 'saYu', headerName: '사유', width: 120, editable: true },
                { field: 'sangDaebang', headerName: '상대방', width: 100, editable: true },
                { field: 'addRess', headerName: '관련부동산', width: 120, editable: true },
              ]}
              pageSize={5}
              autoHeight
              getRowId={(row) => row.gNum}
              pagination={false}
              hideFooterPagination={true}
              hideFooter={true}
              onRowClick={(params) => handleEditCaemu(params.row)}
              getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
            />
          </Grid>
          <Grid item xs={12} sm={12}  style={{ marginTop: '16px' }}>
            <Typography variant="h7" gutterBottom sx={{
    fontWeight: 'bold', // 글자를 두껍게
    color: '#315df9', // 색상을 파란색으로 설정 (원하는 색상으로 변경 가능)
  }}>사업자(개인,법인)
             <IconButton
    color="secondary"
    size="small"
    onClick={handleAddCompany}
    style={{ marginLeft: '10px', color: 'red' }} // 빨간색 설정
  >
    <AddIcon />
    </IconButton>
            </Typography>
            <DataGrid
              rows={companyData}
              columns={[
                { field: 'companyName', headerName: '회사 이름', width: 150, editable: true },
                { field: 'companyDivi', headerName: '구분', width: 60, editable: true },
                { field: 'daeName', headerName: '대표자명', width: 80, editable: true },
                { field: 'saNumber', headerName: '사업자번호', width: 120, editable: true },
                { field: 'giSawonName', headerName: '기장담당자', width: 80, editable: true },
                { field: 'giStartDate', headerName: '기장시작일', width: 120, editable: true },
                { field: 'giEndDate', headerName: '기장종료일', width: 120, editable: true },
                { field: 'giMoney', headerName: '기장료(Vat포함)', width: 120, editable: true , valueFormatter: (params) => {
                  if (params == null) {
                    return '';
                  }
                  if (params == "") {
                    return '';
                  }
                  return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
                renderCell: (params) => (
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    {params.formattedValue}
                  </div>
                )
              },
              ]}
              pageSize={5}
              autoHeight
              getRowId={(row) => row.gNum}
              pagination={false}
                hideFooterPagination={true}
              hideFooter={true}
              onCellClick={handleRowClick} // onRowClick 대신 onCellClick 사용
              getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
            />
          </Grid>
          <Grid item xs={12} sm={12}  style={{ marginTop: '16px' }}>
            <Typography variant="h7" gutterBottom sx={{
    fontWeight: 'bold', // 글자를 두껍게
    color: '#315df9', // 색상을 파란색으로 설정 (원하는 색상으로 변경 가능)
  }}> 주주명부
             <IconButton
    color="secondary"
    size="small"
    onClick={handleAddCompanyjusic}
    style={{ marginLeft: '10px', color: 'red' }} // 빨간색 설정
  >
    <AddIcon />
    </IconButton>
            </Typography>
            <DataGrid
              rows={companyjusicData}
              columns={[
                { field: 'companyName', headerName: '주권발행법인', width: 200, editable: true },
                { field: 'jujuname', headerName: '주주명', width: 100, editable: true },
                { field: 'jusicsu', headerName: '주식수', width: 100, editable: true , valueFormatter: (params) => {
                  if (params == null) {
                    return '';
                  }
                  return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                },
                renderCell: (params) => (
                  <div style={{ textAlign: 'right', width: '100%' }}>
                    {params.formattedValue}
                  </div>
                )
              },
                { field: 'reLation', headerName: '특관여부(관계)', width: 200, editable: true },
                { field: 'rateYu', headerName: '비율', width: 120, editable: true },
              ]}
              pageSize={5}
              autoHeight
              getRowId={(row) => row.gNum}
              pagination={false}
                hideFooterPagination={true}
              hideFooter={true}
              onRowClick={(params) => handleEditCompanyjusic(params.row)}
              getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
            />
          </Grid>

        </Grid>



        </Grid>
        <Grid item xs={12} sm={5}>
        <Grid container >
            <Grid item xs={12} sm={12}>
            <div ref={editSectionRef} style={{ marginTop: '20px' }}>
            <ReactQuill 
  value={content.content} 
  onChange={(value) => setContent(prevState => ({
    ...prevState,
    content: value
  }))} 
  placeholder='상담내용을 적어주세요' 
/>
</div>
            </Grid>
            <Grid item xs={12} sm={12}>  <Button  variant="contained"   color="primary" size="large" onClick={()=>regSangdam()}>{content.gNum.toString().length > 0 ?'수정하기': '등록하기'}</Button>
            &nbsp;{content.gNum.toString().length > 0 ? <Button variant="contained"   color="primary" size="large"
               onClick={()=>{setContent({content:'',gNum:''});}}
            >취소</Button>: ''}
            </Grid>
            <Grid  item xs={12} >
              {/*}
            <TableContainer  Style="Height:570px">
        <Table stickyHeader aria-label="sticky table" >
        */}
          <TableContainer>
          <Table >
          <TableHead>
            <TableRow>
                 <TableCell>

                   <FormControlLabel
                     control={<Checkbox  checked={ovviewyn.jinview1}
                     onChange={() => {
                                   toggleview1();
                                }}
                     name="jinview1" />}
                     label="상담"
                     color="primary"
                  />

                   <FormControlLabel
                     control={<Checkbox  checked={ovviewyn.jinview2}
                     onChange={() => {
                                   toggleview2();
                                }}
                     name="jinview2" />}
                     label="메일"
                     color="primary"
                  />

                  <FormControlLabel
                     control={<Checkbox  checked={ovviewyn.jinview3}
                     onChange={() => {
                                   toggleview3();
                                }}
                     name="jinview3" />}
                     label="문자"
                     color="primary"
                  />

                  

                    (진행건수 : {rows.length}건)
                </TableCell>
            </TableRow>
          </TableHead>
         
          <TableBody>
            
            {          
            rows !== null && rows.map((row) => {
              return (
                <TableRow hover  tabIndex={-1} key={row.id}>
                    <TableCell key={row["gNum"]}  >
                    {row["regDate"].substring(2,10) + ' ' + row["regDate"].substring(11,16)} [{row["sawonName"]}] &nbsp;
                    <Chip  variant="outlined"  label={(row["readYn"] === "N") ? '메일' : (row["readYn"] === "S") ? '상담' : '문자'} color={(row["readYn"] === "N") ? "primary" : (row["readYn"] === "S") ? "primary" : 'primary'} 
                     onDelete={() => {handleDeleteRecord(row["gNum"])}}/>&nbsp;
                    <Button  variant="contained"   color="primary" style={{ minHeight: '20px', padding: '1px 4px' }}  onClick={()=>{onJINmodify(row)}}  >수정</Button>
                    <br />
                    <p dangerouslySetInnerHTML={{ __html: row["mailContents"] }} style={{ lineHeight: '1.0' }} />
                     </TableCell>
                </TableRow>
                );
              })}
          
          </TableBody>
          </Table>
          </TableContainer>
          </Grid>
        </Grid>
        </Grid>
        </Grid>



      </DialogContent>
      <DialogActions>
        <Button onClick={thishandleClose} color="primary">
          닫기
        </Button>
        
        <Button onClick={saveChanges} color="primary">
          {isUpdate ? '저장' : '입력'}
        </Button>
        {isUpdate && (
          <Button onClick={() => setConfirmOpen(true)} color="error">
            삭제
          </Button>
        )}
      </DialogActions>

      {/* 삭제 확인 다이얼로그 */}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>정말 삭제하시겠습니까?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            취소
          </Button>
          <Button onClick={deleteRecord} color="error">
            삭제
          </Button>
        </DialogActions>
      </Dialog>

      {/* 가족 추가/수정 다이얼로그 */}
      <FamilyMemberForm
        open={familyDialogOpen}
        handleClose={handleFamilyFormClose}
        data={selectedFamilyMember}
        handleSave={handleSaveFamilyMember}
        hadnleDelete={handleDeleteFamilyMember}
        filterCartDataByDiviNum={filterCartDataByDiviNum}
      
      />

      <ServiceForm
       open={serviceDialogOpen}
        handleClose={handleServiceFormClose}
        data={selectedService}
        handleSave={handleSaveService}
        handleDelete={handleDeleteService}
        filterCartDataByDiviNum={filterCartDataByDiviNum}
        familyData={familyData} // 가족 데이터를 전달
      />


      <MoneyForm
       open={moneyDialogOpen}
        handleClose={handleMoneyFormClose}
        data={selectedMoney}
        handleSave={handleSaveMoney}
        handleDelete={handleDeleteMoney}
        filterCartDataByDiviNum={filterCartDataByDiviNum}
        familyData={familyData} // 가족 데이터를 전달
      />

      <YugapaperForm
       open={yugaDialogOpen}
        handleClose={handleYugaFormClose}
        data={selectedYuga}
        handleSave={handleSaveYuga}
        handleDelete={handleDeleteYuga}
        filterCartDataByDiviNum={filterCartDataByDiviNum}
        familyData={familyData} // 가족 데이터를 전달
      />

      <BudongsanForm
       open={budongsanDialogOpen}
        handleClose={handleBudongsanFormClose}
        data={selectedBudongsan}
        handleSave={handleSaveBudongsan}
        handleDelete={handleDeleteBudongsan}
        filterCartDataByDiviNum={filterCartDataByDiviNum}
        familyData={familyData} // 가족 데이터를 전달
      />

      <CaemuForm
       open={caemuDialogOpen}
        handleClose={handleCaemuFormClose}
        data={selectedCaemu}
        handleSave={handleSaveCaemu}
        handleDelete={handleDeleteCaemu}
        filterCartDataByDiviNum={filterCartDataByDiviNum}
        familyData={familyData} // 가족 데이터를 전달
      />

        <CompanyForm
       open={companyDialogOpen}
        handleClose={handleCompanyFormClose}
        data={selectedCompany}
        handleSave={handleSaveCompany}
        handleDelete={handleDeleteCompany}
        filterCartDataByDiviNum={filterCartDataByDiviNum}
        familyData={familyData} // 가족 데이터를 전달
      />


      <CompanyjusicForm
       open={companyjusicDialogOpen}
        handleClose={handleCompanyjusicFormClose}
        data={selectedCompanyjusic}
        handleSave={handleSaveCompanyjusic}
        handleDelete={handleDeleteCompanyjusic}
        filterCartDataByDiviNum={filterCartDataByDiviNum}
        familyData={familyData} // 가족 데이타를 전달
        companyData={companyData}
        companyjusicData={companyjusicData}
        handleEditCompanyjusic={handleEditCompanyjusic}
      />

        {/* SMS 발송 폼 */}
       {smsSend.opensmsform && (
        <SmsForm
          open={smsSend.opensmsform}
          selectedCustomersms={selectedCustomersms}
          setSelectedCustomersms={setSelectedCustomersms}
          smsSendRef = {smsSend}
        />
      )}



       {/* 메일 발송 폼 */}
       {mailDialogOpen && (
        <MailsendForm
          open={mailDialogOpen}
          onClose={handleMailDialogClose}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          cartselected={"0"}
        />
      )}
    </Dialog>
  );
};

export default CustomerModifyForm2;