// 숫자 -> 날짜 형식 (YYYY-MM-DD)
export const formatDate = (input) => {
    const cleaned = input.replace(/\D/g, ''); // 숫자 이외의 값 제거
    if (cleaned.length <= 4) {
      return cleaned; // 연도만 입력된 상태
    } else if (cleaned.length <= 6) {
      return `${cleaned.slice(0, 4)}-${cleaned.slice(4, 6)}`; // 연도-월
    } else {
      return `${cleaned.slice(0, 4)}-${cleaned.slice(4, 6)}-${cleaned.slice(6, 8)}`; // 연도-월-일
    }
  };
  
  // 숫자 -> 생년월일 형식 (YYMMDD-1 또는 YYMMDD-2)
  export const formatBirthday = (input) => {
    const cleaned = input.replace(/\D/g, ''); // 숫자 이외의 값 제거
    if (cleaned.length <= 6) {
      return cleaned; // YYMMDD까지만 입력된 상태
    } else {
      return `${cleaned.slice(0, 6)}-${cleaned.slice(6, 7)}`; // YYMMDD-1 또는 YYMMDD-2
    }
  };
  
  // 숫자 -> 휴대폰 번호 형식 (010-0000-0000)
  export const formatPhoneNumber = (input) => {
    const cleaned = input.replace(/\D/g, ''); // 숫자 이외의 값 제거
    if (cleaned.length <= 3) {
      return cleaned; // 010만 입력된 상태
    } else if (cleaned.length <= 7) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 7)}`; // 010-0000까지 입력된 상태
    } else {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 7)}-${cleaned.slice(7, 11)}`; // 010-0000-0000 형식
    }
  };
  