import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Select, FormControl, InputLabel, FormControlLabel, Checkbox } from '@mui/material';
import { formatDate, formatBirthday, formatPhoneNumber } from './functions';
const EmployeeEditForm = ({ open, handleClose, data, handleSave, handleDelete, parentCategories }) => {
  const [formData, setFormData] = useState(data);
  const [confirmOpen, setConfirmOpen] = useState(false); // 삭제 확인 다이얼로그 상태

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    if (name === 'msHp') {
      setFormData({ ...formData, [name]: formatPhoneNumber(value) });
    } else {
      setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    }

  };

  const saveChanges = () => {
    handleSave(formData);
  };

  const deleteRecord = () => {
    handleDelete(formData);
  };

  const isUpdate = !!formData.gNum;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{isUpdate ? '수정하기' : '신규 입력'}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="msId"
          label="아이디"
          type="text"
          fullWidth
          value={formData.msId}
          onChange={handleChange}
          autoComplete="off"
        />
        <TextField
          margin="dense"
          name="msPass"
          label="비밀번호"
          type="password"
          fullWidth
          value={formData.msPass}
          onChange={handleChange}
          autoComplete="new-password"
        />
        <TextField
          margin="dense"
          name="msName"
          label="이름"
          type="text"
          fullWidth
          value={formData.msName}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="msHp"
          label="휴대폰 번호"
          type="text"
          fullWidth
          value={formData.msHp}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="msTel"
          label="전화 번호"
          type="text"
          fullWidth
          value={formData.msTel}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          name="msEmail"
          label="이메일"
          type="email"
          fullWidth
          value={formData.msEmail}
          onChange={handleChange}
        />
         <TextField
          margin="dense"
          name="googlep"
          label="구글비밀번호"
          type="password"
          fullWidth
          value={formData.googlep}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>레벨</InputLabel>
          <Select
             label="레벨"
            name="msLevel"
            value={formData.msLevel || 0}
            onChange={handleChange}
            fullWidth
          >
            {[...Array(11).keys()].map((level) => (
              <MenuItem key={level} value={level}>
                {level}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
         {/* 고객 권한 부분을 숨김 처리 */}
         {false && (
          <>
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.customerReadYn}
              onChange={handleChange}
              name="customerReadYn"
              color="primary"
            />
          }
          label="고객 읽기 권한"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.customerWriteYn}
              onChange={handleChange}
              name="customerWriteYn"
              color="primary"
            />
          }
          label="고객 쓰기 권한"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.customerModifyYn}
              onChange={handleChange}
              name="customerModifyYn"
              color="primary"
            />
          }
          label="고객 수정 권한"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={formData.customerDelYn}
              onChange={handleChange}
              name="customerDelYn"
              color="primary"
            />
          }
          label="고객 삭제 권한"
        />
       
        </>
         )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          취소
        </Button>
        {isUpdate && (
          <Button onClick={() => setConfirmOpen(true)} color="error">
            삭제
          </Button>
        )}
        <Button onClick={saveChanges} color="primary">
          {isUpdate ? '저장' : '입력'}
        </Button>
      </DialogActions>

      {/* 삭제 확인 다이얼로그 */}
      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>정말 삭제하시겠습니까?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            취소
          </Button>
          <Button onClick={deleteRecord} color="error">
            삭제
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default EmployeeEditForm;
