import React, { useState,useEffect } from "react";
import { Container, Typography, Button, TextField, Box, Grid, Switch, FormControlLabel, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';
import api from "../api"; // 이 부분은 상황에 따라 적절하게 변경해야 합니다.
import { tr } from "date-fns/locale";
import moment from "moment";
import { formatDate, formatBirthday, formatPhoneNumber } from './functions';
const columns1 = [
  { field: "ms_name", headerName: "이름", width: 220 },
  { field: "ms_hp", headerName: "핸드폰", width: 150 },
  { field: "id", headerName: "", width: 50,
    renderCell: (params) => (
      <IconButton><DeleteIcon fontSize="small" 
      /></IconButton>
),
  },
];

const columns2 = [
  { field: "hpnumber", headerName: "수신번호", width: 220 },
  { field: "id", headerName: "", width: 50,
    renderCell: (params) => (
      <IconButton><DeleteIcon fontSize="small" 
      /></IconButton>
),
  },
];

const columns3 = [
  { field: "msg", headerName: "내용", width: 370 },
  { field: "id", headerName: "", width: 50,
    renderCell: (params) => (
      <IconButton><DeleteIcon fontSize="small"
      /></IconButton>
),
  },
];

function SmsForm({ onClose, smsSendRef }) {
  const [smsSend, setSmsSend] = useState({
    adminid: localStorage.getItem('msId'),
    msid: "",
    opensmsform: false,
    send_number: "01062292523",
    receive_number: [], // 배열로 변경
    send_text: "",
    send_subject: "",
    send_type: "",
    customer_code: [], // 배열로 변경
    customer_name:  [], // 배열로 변경
    order_code: "",
    reservationYn:"N",
    reservation_date:moment().format("YYYY-MM-DD"),
    reservation_time:moment().format("HH:mm:ss"),
    viewselecttype:true,
  });


  useEffect(() => {
    if (smsSendRef && Object.keys(smsSendRef).length > 0) {
      setSmsSend(smsSendRef);
    }
  }, [smsSendRef]);


  const fetchSmsSaveList = async () => {
    try {
        const response = await api.post('/sm/getSmsSendList');
        setSmssavelist(response.data);
    } catch (error) {
        alert('SMS 저장 목록을 가져오는데 오류가 발생했습니다.');
    }
};

const fetchSmsMsgList = async () => {
    try {
        const response = await api.post('/sm/getSmsMsgList');
        setSmsmsglist(response.data);
    } catch (error) {
        alert('SMS 메시지 목록을 가져오는데 오류가 발생했습니다.');
    }
};


  useEffect(() => {
    if (smsSend.opensmsform) { // 창이 열릴 때만 데이터를 가져옴
       

        fetchSmsSaveList();
        fetchSmsMsgList();
    }
}, [smsSend.opensmsform]); // `open` 상태가 변경될 때마다 실행

  const [smsnewinput, setSmsnewinput] = useState({ save_name: "", save_hp: "" });
  const [smssavelist, setSmssavelist] = useState([]);
  const [smsmsglist, setSmsmsglist] = useState([]);
  const [susinnumber, setSusinnumber] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const addsmsmsg = async (send_text) => {
    try {
        const response = await api.post('/sm/insertSmsMsgList', {
            msg: send_text,  // 문자 내용을 msg로 보냄
            msg_img: ""  // 이미지가 없는 경우 빈 문자열로 처리 (이미지가 있으면 여기에 경로를 추가)
        });

        if (response.data.Result) {
          
            fetchSmsMsgList();
        } else {
            console.error('메세지 저장에 실패했습니다.');
        }
    } catch (error) {
        console.error('메세지 저장 중 오류가 발생했습니다:', error);
        alert('메세지 저장 중 오류가 발생했습니다.');
    }
};

const handleSaveMessage = () => {
  addsmsmsg(smsSend.send_text);  // 문자 내용만 서버에 저장
};



  const handleSmsSendChange = (event) => {
    const { name, value, checked, type } = event.target;
    setSmsSend({
      ...smsSend,
      [name]: type === "checkbox" ? (checked ? "N" : "Y") : value,
    });
  };

  const closeForm = () =>{
    setSmsSend({
      ...smsSend,
      opensmsform: false,
    });
  }

  const handleSmsNewInputChange = (event) => {
    const { name, value } = event.target;
    setSmsnewinput({
      ...smsnewinput,
      [name]: value,
    });
  };

  const handleAddRecipient = () => {
    if (!susinnumber) {
      alert("핸드폰 번호를 입력해주세요.");
      return;
    }
    
     // 새로운 수신자 추가
  setSmsSend((prevSmsSend) => ({
    ...prevSmsSend,
    receive_number: [
      ...prevSmsSend.receive_number,
      {hpnumber: susinnumber,id:prevSmsSend.receive_number.length+1 }
    ]
  }));
  setSusinnumber("");
  };


  const cksetsmsListinput = async () => {
    try {
        // 상태에서 이름과 전화번호 가져오기
        const { save_name, save_hp } = smsnewinput;

        // 서버로 POST 요청 보내기
        const response = await api.post('/sm/insertSmsSendList', {
            ms_name: save_name,
            ms_hp: save_hp,
        });

        // 저장 성공: 서버에서 레코드 번호가 반환되면 해당 번호를 확인
        if (response.data.Result && !isNaN(response.data.Result)) {
          // 저장이 성공적일 때: fetchSmsSaveList 함수 호출하여 리스트 업데이트
          fetchSmsSaveList();
          alert(`전화번호가 성공적으로 저장되었습니다. 레코드 번호: ${response.data.Result}`);
          // 상태 초기화
          setSmsnewinput({ save_name: "", save_hp: "" });
      } else {
          // 실패 메시지를 알림
          alert(`전화번호 저장에 실패했습니다: ${response.data.Result}`);
      }
    } catch (error) {
        console.error('전화번호 저장 중 오류가 발생했습니다:', error);
        alert('전화번호 저장 중 오류가 발생했습니다.');
    }
};


  const addRecevelist = () => {
    if (!susinnumber) {
      alert("핸드폰 번호를 입력해주세요.");
      return;
    }
    
     // 새로운 수신자 추가
  setSmsSend((prevSmsSend) => ({
    ...prevSmsSend,
    receive_number: [
      ...prevSmsSend.receive_number,
      {hpnumber: susinnumber,id:prevSmsSend.receive_number.length+1 }
    ]
  }));
  setSusinnumber("");
  };


  const handleDeleteRecipient = (id) => {
    setSmsSend((prevSmsSend) => {
      // 해당 id를 가진 항목 찾기
      const recipientToDelete = prevSmsSend.receive_number.find(item => item.id === id);
      if (!recipientToDelete) return prevSmsSend; // 만약 찾지 못하면 이전 상태 그대로 반환
  
      // 삭제할 전화번호를 기준으로 고객 코드와 이름에서도 삭제
      const updatedReceiveNumber = prevSmsSend.receive_number && prevSmsSend.receive_number.filter(item => item.id !== id);
      const updatedCustomerCode = prevSmsSend.customer_code && prevSmsSend.customer_code.filter((_, index) => prevSmsSend.receive_number[index].hpnumber !== recipientToDelete.hpnumber);
      const updatedCustomerName = prevSmsSend.customer_name && prevSmsSend.customer_name.filter((_, index) => prevSmsSend.receive_number[index].hpnumber !== recipientToDelete.hpnumber);
  
      return {
        ...prevSmsSend,
        receive_number: updatedReceiveNumber,
        customer_code: updatedCustomerCode,
        customer_name: updatedCustomerName
      };
    });
  };

  const handleDeleteMessage = async (id) => {
    const confirmed = window.confirm("정말로 이 메시지를 삭제하시겠습니까?");
    
    if (confirmed) {
        try {
            // 서버로 삭제 요청을 보냄
            const response = await api.post('/sm/deleteSmsMsgList', { g_num: id });

            if (response.data.Result === 'Y') {
                // 서버에서 성공적으로 삭제된 경우 로컬 상태에서도 삭제
                fetchSmsMsgList();
            } else {
                alert('메시지 삭제에 실패했습니다.');
            }
        } catch (error) {
            console.error('메시지 삭제 중 오류가 발생했습니다:', error);
            alert('메시지 삭제 중 오류가 발생했습니다.');
        }
    }
  };

  const handleDeleteCustomer = async (g_num) => {
    const confirmed = window.confirm("정말로 이 번호를 삭제하시겠습니까?");
    
    if (confirmed) {
        try {
            const response = await api.post('/sm/deleteSmsSendList', { g_num });

            if (response.data.Result === 'Y') {
                 fetchSmsSaveList();
                alert('고객이 성공적으로 삭제되었습니다.');
            } else {
                alert(`고객 삭제에 실패했습니다: ${response.data.Result}`);
            }
        } catch (error) {
            console.error('고객 삭제 중 오류가 발생했습니다:', error);
            alert('고객 삭제 중 오류가 발생했습니다.');
        }
    }
};

  const handleSmsSend = async () => {
    const confirmed = window.confirm("문자를 발송합니까?");
    
    if (confirmed) {
        try {
            const response = await api.post('/sm/getRealSmsSend', { smsSend });

            if (response.data.result_a === 'Y') {
              closeForm();
            } else {
                alert(`문자발송에 실패했습니다: ${response.data.result_a}`);
            }
        } catch (error) {
            console.error('문자발송 중 오류가 발생했습니다:', error);
            alert('문자발송 중 오류가 발생했습니다.');
        }
    }
  };

  const toggleView = () => {
    setSmsSend({
      ...smsSend,
      viewselecttype: !smsSend.viewselecttype,
    });
  };

  const onChangsetSmsSend = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setSmsSend({
      ...smsSend, // 기존의 input 객체를 복사한 뒤
      [name]: value, // name 키를 가진 값을 value 로 설정,
    });
  };

  const onChangsmsnewinput = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setSmsnewinput({
      ...smsnewinput, // 기존의 input 객체를 복사한 뒤
      [name]: value, // name 키를 가진 값을 value 로 설정,
    });
  };

  
  const handleSmssusinnumberChange = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    const formattedValue = formatPhoneNumber(value);
    setSusinnumber(formattedValue);
  };

  
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post('/cf/insertsmsfileupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
       // console.log(response);
      const response2 = await api.post('/cf/NAVERSHOTCUTURL', {urladdress:'https://reservation.dytaxgroup.com/smssendfile/'+response.data.originalname});
      //console.log(response2);
      if (response2 && response2.data && response2.data.result)
      {
       // console.log("들어갔다");
        setSmsSend((prevSmsSend) => ({
          ...prevSmsSend,
          send_text: prevSmsSend.send_text + '\n' + response2.data.result.url
        }));
      }
     // setUploadedFiles(prevFiles => [
     //   ...prevFiles,
     //   { id: prevFiles.length + 1, filename: response.data.filename, originalname: response.data.file.name }
     // ]);
    } catch (error) {
      console.error('File upload failed:', error);
      alert('파일 업로드 중 오류가 발생했습니다.');
    }
  };



  const toggleReservView = () => {
    setSmsSend({
      ...smsSend,
      reservationYn: smsSend.reservationYn == "N" ? "Y":"N",
    });
  };
  
  const customerNames = smsSend.customer_name; // 이름 리스트 (배열 형태로 저장되었다고 가정)
  const customerCodes = smsSend.customer_code; // 코드 리스트 (배열 형태로 저장되었다고 가정)
  
  const firstName = Array.isArray(customerNames) && customerNames.length > 0 ? customerNames[0] : "";
  const remainingCount = customerNames.length - 1;


  const clickchangenumnber=(nuymber)=>{
    setSmsSend({
     ...smsSend, // 기존의 input 객체를 복사한 뒤
     send_number: nuymber,

   });
 }


  return (
    <Dialog open={smsSend.opensmsform} onClose={closeForm} maxWidth="md" fullWidth>
      <DialogTitle>
        문자보내기 [
    {firstName && ` ${firstName}`}
    {remainingCount > 0 && ` 외 ${remainingCount}명 `}
     ]
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} >
          <Grid item xs={5} >
            <Grid container spacing={1} >
              <Grid item xs={6} >
            <TextField
              fullWidth
              id="send_number"
              name="send_number"
              label="회신번호(발송처)"
              value={smsSend.send_number}
              variant="outlined"
              size="small"
              onChange={handleSmsSendChange}
            />
              </Grid>
              <Grid item xs={3} >
               <Button variant="contained" size="small" color="primary" fullWidth  onClick={()=>clickchangenumnber("0220512020")}>2020</Button>
              </Grid>
              <Grid item xs={3} >
              <Button variant="contained" size="small" color="primary" fullWidth  onClick={()=>clickchangenumnber("01062292523")}>2523</Button>
              </Grid>

              <Grid item xs={7} >
            <TextField
              fullWidth
              id="susinnumber"
              name="susinnumber"
              label="받는번호(수신처)"
              variant="outlined"
              size="small"
              value={susinnumber}
              onChange={handleSmssusinnumberChange}
            />
            </Grid>
            <Grid item xs={5} >
            <Button variant="contained" size="large" color="primary" onClick={addRecevelist}>
              수신 추가
            </Button>
            </Grid>
            <Grid container item spacing={1} >
            <Grid item xs={6} >
            <TextField
              fullWidth
              id="reservation_date"
              name="reservation_date"
              label="예약일"
              variant="outlined"
              size="small"
              type="date"
              value={smsSend.reservation_date}
              onChange={onChangsetSmsSend}
            />

            </Grid>
            <Grid item xs={6} >
            <TextField
              fullWidth
              id="reservation_time"
              name="reservation_time"
              label="예약시간"
              variant="outlined"
              size="small"
              type="time"
              value={smsSend.reservation_time}
              onChange={onChangsetSmsSend}
            />
            </Grid>
            <Grid item xs={12} >
              <FormControlLabel
                    control={
                      <Switch
                      checked={smsSend.reservationYn ==='N' ? true : false}
                      onClick={toggleReservView}
                      id="reservationYn"
                      name="reservationYn"
                      color="primary"
                      />
                    }
                     label={smsSend.reservationYn ==='N' ? "지금 즉시 발행합니다" : "예약발행입니다"}
                />
               
            </Grid>

            </Grid>


            <Grid item xs={12} >
            <div style={{ height: 310, width: "98%" }}>
              <DataGrid
                rows={smsSend.receive_number}
                columns={columns2}
                pageSize={100}
                hideFooterPagination  
                hideFooter
                headerHeight={30}
                onCellClick={(cellData) =>{
                  if (cellData.field === "id") {
                    handleDeleteRecipient(cellData.id);  // cellData.id를 사용
                  }
                }
                
                }
              />
            </div>
          </Grid>
          </Grid>
          </Grid>

          <Grid item xs={7} >
            <Grid container spacing={1}>
            <Grid item xs={12} >
            <TextField
              rows="5"
              multiline
              fullWidth
              id="send_text"
              name="send_text"
              label="문자내용"
              value={smsSend.send_text}
              variant="outlined"
              onChange={handleSmsSendChange}
            />
            </Grid>
            <Grid item xs={6} >
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        사진선택&단축주소
                      </Button>
                    </label>
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      name="image1"
                      type="file"
                      multiple="multiple"
                      //value={uploadedFiles}
                      onChange={handleFileUpload}
                      hidden
                    />
                    
                    </Grid>
                    <Grid item xs={3} >
                     <Button variant="contained"  color="primary"  onClick={()=>handleSaveMessage()}
                     >
                      문자 내용저장
                    </Button>
                    </Grid>
                    <Grid item xs={3} >
                    <Button variant="contained"  color="primary"  onClick={()=>toggleView()}
                    >
                      {smsSend.viewselecttype ? "내용선택하기":"명단선택하기"}
                    </Button>
                  </Grid>
                  <Grid item xs={12} >
            <div style={{ height: 310, width: "98%" }} hidden={!smsSend.viewselecttype ? true:false}>
              <DataGrid
                rows={smssavelist}
                columns={columns1}
                pageSize={100}
                hideFooterPagination
                hideFooter
                headerHeight={30}
                getRowId={(row) => row.g_num}  // g_num을 id로 사용
                onCellClick={(cellData) => {
                  if (cellData.field === "id") {
                    handleDeleteCustomer(cellData.row.g_num)
                  }else{
                    // 새로운 수신자 추가
  setSmsSend((prevSmsSend) => ({
    ...prevSmsSend,
    receive_number: [
      ...prevSmsSend.receive_number,
      {hpnumber: cellData.row.ms_hp,id:prevSmsSend.receive_number.length+1 }
    ]
  }));
                  }
                }}
              />
            </div>

            <div style={{ height: 310, width: "98%" }} hidden={smsSend.viewselecttype ? true:false}>
              <DataGrid
                rows={smsmsglist}
                columns={columns3}
                pageSize={100}
                hideFooterPagination
                hideFooter
                headerHeight={30}
                getRowId={(row) => row.g_num}  // g_num을 id로 사용
                onCellClick={(cellData) => {
                  if (cellData.field === "id") {
                    // g_num을 handleDeleteCustomer에 전달
                    handleDeleteMessage(cellData.row.g_num);
                  } else if (cellData.field === "msg") {
                    // ms_hp 값을 handleAddRecipient에 전달
                    handleSmsSendChange({ target: { name: "send_text", value: cellData.value } })
                    
                  }
                }}
              />
            </div>
            </Grid>
          </Grid>
          </Grid>
        </Grid>
      

      <Grid item xs={12}>
             <Grid container spacing={1}>
                  <Grid item xs={2}>
                      <Button variant="contained"  color="primary" size="large" onClick={()=>handleSmsSend()} fullWidth>발송하기</Button>
                  </Grid>
                  <Grid item xs={8}>
                    
                        <Grid item xs={12}>
                           <Grid container spacing={1}>
                             <Grid item xs={4}>
                               <TextField fullWidth id="save_name" name="save_name" label="저장할 이름" variant="outlined" size="small" value={smsnewinput.save_name} onChange={onChangsmsnewinput}/>
                             </Grid>
                             <Grid item xs={4}>
                                <TextField fullWidth id="save_hp" name="save_hp" label="저장할 번호" variant="outlined" size="small" value={smsnewinput.save_hp} onChange={onChangsmsnewinput}/>
                             </Grid>
                             <Grid item xs={4}>
                                <Button variant="contained"  color="primary" size="large" onClick={()=>cksetsmsListinput()}>번호 저장</Button>
                             </Grid>
                           </Grid>
                      </Grid>
                  </Grid>
                  <Grid item xs={2}>
                      <Button  variant="contained"  onClick={closeForm} color="primary" size="large" fullWidth >창닫기</Button>
                  </Grid>
            </Grid>
          </Grid>


          </DialogContent>
          <DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default SmsForm;
