import React, { useState, useEffect } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import api from '../api'; // Axios 인스턴스 가져오기
import Basiccode1editform1 from './Basiccode1editform1';
import '../style.css'; // CSS 파일 임포트


const Basiccode1 = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const fetchData = async () => {
    try {
      const response = await api.post('/di/selectallAdivi');
      const rowsWithId = response.data.map((row, index) => ({ id: row.gNum || index, ...row }));
      setData(rowsWithId);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };

  const handleSave = async (updatedData) => {
    try {
      if (updatedData.gNum) {
        // 기존 데이터 수정
        await api.post('/di/updateAdivi', updatedData);
        setData((prevData) => prevData.map((row) => (row.gNum === updatedData.gNum ? updatedData : row)));
      } else {
        // 신규 데이터 입력
        const response = await api.post('/di/insertAdivi', updatedData);
        fetchData(); // 전체 데이터를 다시 가져오기
      }
      handleClose();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleDelete = async (dataToDelete) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    
    try {
      await api.post('/di/deleteAdivi', dataToDelete);
      setData((prevData) => prevData.filter((row) => row.gNum !== dataToDelete.gNum));
      handleClose();
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleAddNew = () => {
    setSelectedRow({ gNum: '', diviName: '' });
    setDialogOpen(true);
  };

  const columns = [
    { field: 'gNum', headerName: '번호', width: 150 },
    { field: 'diviName', headerName: '분류명', width: 300 },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ display: 'flex', alignItems: 'center', margin: '16px' }}>
        <Typography variant="h4" style={{ flexGrow: 1 }}>
          기초분류 관리
        </Typography>
        <Button variant="contained" color="primary" onClick={handleAddNew} style={{ marginLeft: '16px' }}>
          신규 입력
        </Button>
      </div>
      <div style={{ flexGrow: 1, margin: '0 16px 16px 16px' }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          loading={loading}
          getRowId={(row) => row.gNum}
          onRowClick={handleRowClick}
          style={{ height: '100%' }} // Adjust the height of the DataGrid
           getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
              }
        />
      </div>
      {selectedRow && (
        <Basiccode1editform1
          open={dialogOpen}
          handleClose={handleClose}
          data={selectedRow}
          handleSave={handleSave}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default Basiccode1;
