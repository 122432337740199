import { createTheme } from '@mui/material';


const theme = createTheme({
  palette: {
    primary: {
      main: '#232F3E', // 검정색
    },
    secondary: {
      main: '#ffffff', // 흰색
    },
    error: {
      main: '#ff0000', // 빨간색
    },
  },

  typography: {
    fontSize: 14, // 기본 글자 크기 설정
    h4: {
      fontSize: '1.5rem', // 반응형 헤더 글자 크기 설정
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
      '@media (min-width:960px)': {
        fontSize: '2.0rem',
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {

          fontSize: '1rem',
          padding: '6px 6px',
          '@media (max-width:600px)': {
            minWidth: '50px',
            fontSize: '0.7rem',
            padding: '4px 8px',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          margin: 'dense',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: 'dense',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '8px 8px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '1024px', // 고정된 최대 너비 설정
          width: '1024px', // 고정된 너비 설정
          margin: '0 auto', // 가운데 정렬
          padding: '16px', // 내부 패딩
          '@media (max-width:600px)': {
            padding: '8px', // 모바일에서의 패딩 조정
          },
        },
      },
    },
    
  },
});

export default theme;