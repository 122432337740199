import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, Button, Paper, Dialog,FormControl,FormLabel,FormGroup
  ,FormControlLabel,Checkbox } from '@mui/material';
import api from '../api'; // Axios 인스턴스 또는 다른 HTTP 클라이언트
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import '../style.css'; // CSS 파일 임포트
import MailsendForm from './MailsendForm';
import SmsForm from './SmsForm';
import moment from "moment";
import { formatDate, formatBirthday, formatPhoneNumber } from './functions';


function ReservationModifyForm({ open, handleClose, handleSave, handleDelete, filterCartDataByDiviNum, gNum, 
  initialData,sendpayment,sendpaymentRe,sendpaymentCancle,sendpaymentState,sendpaymentDestroy }) {
  const [formData, setFormData] = useState({
    gNum: '',
    orderId: '',
    reserDate: '',
    reserTime: '',
    selectSangdam: '',
    customerName: '',
    customerHp1: '',
    customerEmail: '',
    moneyCheck: '',
    totalMoney: '',
    inMoney: '',
    notMoney: '',
    googleCheck: '',
    customerGnum: '',
    adminCheck: '',
    regDate: '',
    contentes: '',
  });



  const [smsSend, setSmsSend] = useState({
    adminid: "",
    msid: "01062292523",
    opensmsform: false,
    send_number: "",
    receive_number: { hpnumber: "", rname: "" },
    send_text: "",
    send_subject: "",
    send_type: "",
    customer_code: "",
    customer_name: "",
    order_code: "",
    reservationYn:"N",
    reservation_date:moment().format("YYYY-MM-DD"),
    reservation_time:moment().format("HH:mm:ss"),
  });




  const [mailDialogOpen, setMailDialogOpen] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]); // 메일 발송에 필요한 고객 정보 관리


  const jinState = filterCartDataByDiviNum(15); // 홈피 선택사항

  const handleCategoryChange = (event) => {
    const { checked, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      selectSangdam: checked
        ? [...prevData.selectSangdam, value]
        : prevData.selectSangdam.filter((category) => category !== value),
    }));
  };

 // 데이터 초기화
 useEffect(() => {
  if (initialData) {
    // 초기화 시 selectSangdam을 배열로 변환 (콤마로 구분된 문자열을 배열로 변환)
    setFormData({
      ...initialData,
      selectSangdam: initialData.selectSangdam ? initialData.selectSangdam.split(',') : []
    });
  }
}, [initialData]);


const handleMailDialogClose = () => {
  setMailDialogOpen(false);
};


/*

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
*/
  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString().split('T')[0]; // yyyy-MM-dd 형식으로 변환
      setFormData((prevData) => ({
        ...prevData,
        reserDate: formattedDate,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        reserDate: '',
      }));
    }
  };

 function formatCurrency(value) {
  if (!value) return '';
  return Number(value).toLocaleString('en-US');
}

   const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = { ...formData, [name]: value.replace(/,/g, '') };

     // 전화번호 필드 처리
  if (name === 'customerHp1') {
    newFormData.customerHp1 = formatPhoneNumber(value); // 전화번호 형식 변환
  }

  
    if (name === 'totalMoney') {
      const total = parseFloat(newFormData.totalMoney) || 0;
      const inMoney = parseFloat(newFormData.inMoney) || 0;
      newFormData.notMoney = (total - inMoney).toString();
    }

    setFormData(newFormData);
  };


    const sendpaymailsend = (e) =>{
      const selectedCustomerData = [{
        id: formData.cCode,
        name: formData.customerName,
        email: formData.customerEmail,
      }];
    
        setSelectedCustomers(selectedCustomerData);
        setMailDialogOpen(true);
    }

    const sendpaysmssend = (e) =>{
          setSmsSend({
            ...smsSend, // 기존의 input 객체를 복사한 뒤
            opensmsform: true,
            send_number: "01062292523",
            receive_number:  [{ hpnumber: formData.customerHp1, id:1 }],
            send_text: "",
            send_subject: "",
            send_type: "",
            customer_code:[formData.cCode],
            customer_name:[formData.customerName],
            order_code: "",
            reservationYn:"N",
            viewselecttype:false,
          });
    }
    

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth >
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          예약 수정/삭제 폼
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <TextField
              label="주문 ID"
              name="orderId"
              value={formData.orderId}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
  <LocalizationProvider dateAdapter={AdapterDateFns} >
    <DatePicker
      label="예약 날짜"
      value={formData.reserDate ? new Date(formData.reserDate) : null}
      onChange={handleDateChange}
      renderInput={(params) => (
        <TextField 
          {...params} 
          fullWidth 
          margin="none"  // margin을 없애서 전체 폭을 채우도록 설정
          InputProps={{ style: { width: '100%' } }}  // width 100% 설정
        />
      )}
      inputFormat="yyyy-MM-dd"
      className="custom-datepicker"
    />
  </LocalizationProvider>
</Grid>
          <Grid item xs={2} alignItems="center">
            <TextField
              label="예약 시간"
              name="reserTime"
              value={formData.reserTime}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
           {/* 카테고리 체크박스 목록 */}
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">선택 상담</FormLabel>
              <FormGroup row>
                {jinState.map((category) => (
                  <FormControlLabel
                    key={category.gNum}
                    control={
                      <Checkbox
                        checked={formData.selectSangdam.includes(category.gNum.toString())}
                        onChange={handleCategoryChange}
                        value={category.gNum.toString()}
                      />
                    }
                    label={category.cartName}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="고객 이름"
              name="customerName"
              value={formData.customerName}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="고객 전화번호"
              name="customerHp1"
              value={formData.customerHp1}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="고객 이메일"
              name="customerEmail"
              value={formData.customerEmail}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="금액 확인 (moneyCheck)"
              name="moneyCheck"
              value={formData.moneyCheck}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="총 금액"
              name="totalMoney"
              value={formatCurrency(formData.totalMoney)}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="입금 금액"
              name="inMoney"
              value={formatCurrency(formData.inMoney)}
              onChange={handleChange}
              fullWidth
              margin="normal"
              InputProps={{
            readOnly: true,
          }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="미입금 금액"
              name="notMoney"
              value={formatCurrency(formData.notMoney)}
              onChange={handleChange}
              fullWidth
              margin="normal"
               InputProps={{
            readOnly: true,
          }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="구글 체크"
              name="googleCheck"
              value={formData.googleCheck}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="내용 (contentes)"
              name="contentes"
              value={formData.contentes}
              onChange={handleChange}
              fullWidth
              multiline
              rows={3}
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '20px' }}>
        <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={() => sendpayment(formData)} fullWidth>
              결제발송
            </Button>
        </Grid>
        <Grid item xs={1.5}>
            <Button variant="contained" color="primary" onClick={() => sendpaymentRe(formData)} fullWidth>
              결제재발송
            </Button>
            </Grid>
            <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={() => sendpaymentCancle(formData)} fullWidth>
              결제취소
            </Button>
            </Grid>
            <Grid item xs={1.5}>
             <Button variant="contained" color="primary" onClick={() => sendpaymentDestroy(formData)} fullWidth>
              청구서파기
            </Button>
            </Grid>
            <Grid item xs={1}>
             <Button variant="contained" color="primary" onClick={() => sendpaymentState(formData)} fullWidth>
              결제상태
            </Button>
            </Grid>
            <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={() => sendpaymailsend(formData)} fullWidth>
              메일
            </Button>
            </Grid>
            <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={() => sendpaysmssend(formData)} fullWidth>
              문자
            </Button>
            </Grid>
          <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={() => handleSave(formData)} fullWidth>
              저장
            </Button>
          </Grid>

          <Grid item xs={1}>
            <Button variant="contained" color="primary" onClick={() =>handleClose()} fullWidth>
              닫기
            </Button>
          </Grid>

          <Grid item xs={1}></Grid>
          {formData.gNum && (
            <Grid item xs={1}>
              <Button variant="contained" color="secondary" onClick={() => handleDelete(formData)} fullWidth>
                삭제
              </Button>
            </Grid>
          )}
          </Grid>
      </Paper>

      {mailDialogOpen && (
        <MailsendForm
          open={mailDialogOpen}
          onClose={handleMailDialogClose}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          cartselected={formData.selectSangdam}
        />
      )}

  {/* SMS 발송 폼 */}
       {smsSend.opensmsform && (
        <SmsForm
          open={smsSend.opensmsform}
          smsSendRef = {smsSend}
        />
      )}
    </Dialog>

    
  );
}

export default ReservationModifyForm;
