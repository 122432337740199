import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { formatDate, formatBirthday, formatPhoneNumber } from './functions';

const FamilyMemberForm = ({ open, handleClose, data, handleSave,hadnleDelete,filterCartDataByDiviNum }) => {
  const [formData, setFormData] = useState(data || {});

  // 기초코드 가져오기


  const genderCodes = filterCartDataByDiviNum(6); // 성별의 Adivi 값 사용
  const localCodes = filterCartDataByDiviNum(7); // 거주구분
  const relationCodes = filterCartDataByDiviNum(4); // 가족관계
  const foreginCodes = filterCartDataByDiviNum(8); // 내외국민

  useEffect(() => {
    setFormData(data || {});
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
   // 생년월일 필드 처리
  if (name === 'cjuMin1') {
    setFormData({ ...formData, [name]: formatBirthday(value) });
  } else {
    setFormData({ ...formData, [name]: value });
  }
  };

  const saveChanges = () => {
    handleSave(formData);
  };

  const deleteChanges = () => {
    hadnleDelete(formData);
  };
  

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{data ? '가족 구성원 수정' : '가족 구성원 추가'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="cName"
              label="이름"
              type="text"
              fullWidth
              value={formData.cName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <InputLabel>가족관계</InputLabel>
              <Select
               label="가족관계"
                name="cDaeRelation"
                value={formData.cDaeRelation || ''}
                onChange={handleChange}
                fullWidth
              >
                {
                  relationCodes.map((code) => (
                    <MenuItem key={code.cartName} value={code.cartName}>
                      {code.cartName}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="cjuMin1"
              label="생년월일"
              type="text"
              fullWidth
              value={formData.cjuMin1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <InputLabel>성별</InputLabel>
              <Select
               label="성별"
                name="cjuMin2"
                value={formData.cjuMin2 || ''}
                onChange={handleChange}
                fullWidth
              >
                {
                  genderCodes.map((code) => (
                    <MenuItem key={code.cartName} value={code.cartName}>
                      {code.cartName}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <InputLabel>거주구분</InputLabel>
              <Select
               label="거주구분"
                name="localYn"
                value={formData.localYn || ''}
                onChange={handleChange}
                fullWidth
              >
                {
                  localCodes.map((code) => (
                    <MenuItem key={code.cartName} value={code.cartName}>
                      {code.cartName}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <InputLabel>내외국민</InputLabel>
              <Select
               label="내외국민"
                name="foreginYn"
                value={formData.foreginYn || ''}
                onChange={handleChange}
                fullWidth
              >
                {
                  foreginCodes.map((code) => (
                    <MenuItem key={code.cartName} value={code.cartName}>
                      {code.cartName}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              margin="dense"
              name="meMo1"
              label="메모"
              type="text"
              fullWidth
              value={formData.meMo1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="cDaeCode"
              label="대표코드"
              type="text"
              fullWidth
              value={formData.cDaeCode}
              onChange={handleChange}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="cCode"
              label="개인코드"
              type="text"
              fullWidth
              value={formData.cCode}
              onChange={handleChange}
               InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          취소
        </Button>
         <Button onClick={deleteChanges} color="primary">
          삭제
        </Button>
        <Button onClick={saveChanges} color="primary">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FamilyMemberForm;
