import React, { useState, useEffect } from 'react';
import { Typography, Button, Container,MenuItem,FormControl,InputLabel,Select,Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import api from '../api'; // Axios 인스턴스 가져오기
import CustomerModifyForm2 from './CustomerModifyForm2';



const ServiceList = () => {
  const [data, setData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCart, setSelectedCart] = useState('진행중'); // 선택된 카트
  const [selectgNum,setSelectgNum] = useState(0);

  const fetchData = async (serviceState) => {
    setLoading(true);
    try {
      const response = await api.post('/se/getAservice', { serviceState });
      const rowsWithId = response.data.map((row, index) => ({ id: row.gNum || index, ...row }));
      setData(rowsWithId);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };


  const fetchCartData = async () => {
    try {
      const response = await api.post('/ca/selectallAcart', { Adivi: 0 });
      setCartData(response.data);
      
    } catch (error) {
      console.error('Error fetching cart data:', error);
    }
  };

  useEffect(() => {
    fetchCartData();
    fetchData('진행중'); // 초기 상태는 '진행중'으로 설정
  }, []);





 const handleRowClick = async (params) => {
    try {
      setSelectgNum(params.row.gnumDae);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectgNum(null);
    fetchData('진행중');
  };

   const filterCartDataByDiviNum = (diviNum) => {
    return cartData.filter(cart => cart.diviNum === diviNum).sort((a, b) => a.sunWe - b.sunWe);
  };

    const handleSave = async (updatedData) => {
    try {
      if (updatedData.gNum) {
        // 기존 데이터 수정
        await api.post('/ct/updateAcustomer', updatedData);
        setData((prevData) => prevData.map((row) => (row.gNum === updatedData.gNum ? updatedData : row)));
      } else {
        // 신규 데이터 입력
        await api.post('/ct/insertAcustomer', updatedData);
        fetchData(); // 전체 데이터를 다시 가져오기
      }
      handleClose();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleDelete = async (dataToDelete) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }
    
    try {
      await api.post('/customer/delete', dataToDelete);
      setData((prevData) => prevData.filter((row) => row.gNum !== dataToDelete.gNum));
      handleClose();
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };



  

 const handleCartChange = (event) => {
    const selectedServiceState = event.target.value;
    setSelectedCart(selectedServiceState);
    fetchData(selectedServiceState); // 선택된 서비스 상태로 데이터를 다시 가져옴
  };


  const handleCellClick = (params, event) => {
    const { field, value } = params;
    if ((field === 'linkAddress1' || field === 'linkAddress2') && value) {
      event.stopPropagation();
      window.open(value, '_blank');
      
    }
  };


  const columns = [
    { field: 'daeName', headerName: '고객명', width: 150 },
    { field: 'serviceTitle', headerName: '서비스 제목', width: 200 },
    { field: 'serviceStartdate', headerName: '서비스 시작일', width: 150 },
    { 
      field: 'serviceMoney', 
      headerName: '금액', 
      width: 110, 
      valueFormatter: (params) => {
        if (params == null) {
          return '';
        }
        return params.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
      renderCell: (params) => (
        <div style={{ textAlign: 'right', width: '100%' }}>
          {params.formattedValue}
        </div>
      )
    },
    { field: 'serviceState', headerName: '상태', width: 110 },
    { field: 'gAb', headerName: '갑', width: 100 },
    { field: 'eUl', headerName: '을', width: 100 },
    { field: 'sMemo', headerName: '메모', width: 150 },
    { field: 'linkAddress1', headerName: '링크 주소 1', width: 150 },
    { field: 'linkAddress2', headerName: '링크 주소 2', width: 150 },
  ];

  return (
   <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      <div style={{ display: 'flex', alignItems: 'center', margin: '16px' }}>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          진행중인 용역 리스트
        </Typography>
         <FormControl style={{ marginLeft: '16px', minWidth: '200px' }}>
          <InputLabel>진행 상태</InputLabel>
          <Select
            label="진행 상태"
            value={selectedCart}
            onChange={handleCartChange}
          >
            {filterCartDataByDiviNum(14).map((cart) => (
              <MenuItem key={cart.cartName} value={cart.cartName}>
                {cart.cartName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

      </div>
      <div style={{ flexGrow: 1, margin: '0 16px 16px 16px' }}>
        <DataGrid
          rows={data}
          columns={columns}
          pageSize={5}
          loading={loading}
          getRowId={(row) => row.gNum}
          onRowClick={handleRowClick}
          onCellClick={handleCellClick} 
          style={{ height: 'calc(100vh - 128px)', width: '100%' }} // Adjust the height of the DataGrid
          getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
              }
        />
      </div>
       {selectgNum && (
        <CustomerModifyForm2
          open={dialogOpen}
          handleClose={handleClose}
          handleSave={handleSave}
          handleDelete={handleDelete}
          filterCartDataByDiviNum={filterCartDataByDiviNum}
          gNum={selectgNum}
        />
      )}
    </Box>
  );
};

export default ServiceList;
