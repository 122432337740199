import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField, Box,Grid,FormControl,InputLabel,Select,MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import api from '../api'; // Axios 인스턴스 가져오기
import CustomerModifyForm2 from './CustomerModifyForm2';
import '../style.css'; // CSS 파일 임포트
import { ConstructionOutlined } from '@mui/icons-material';
import MailsendForm from './MailsendForm';
import SmsForm from './SmsForm';
import moment from "moment";



const CustomerManagement2 = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [cLevel, setCLevel] = useState('');
  const [cJaesan,setCJaesan] = useState('');
  const [cjuMin2,setCjuMin2] = useState('');
  const [selectedRows, setSelectedRows] = useState([]); // 선택된 행 관리
  const [endDate, setEndDate] = useState(null);
  const [cartData, setCartData] = useState([]);
  const [selectgNum,setSelectgNum] = useState(0);
  const [mailDialogOpen, setMailDialogOpen] = useState(false);
  const [smsDialogOpen, setSmsDialogOpen] = useState(false); // SMS 발송 다이얼로그 상태
  const [selectedCustomers, setSelectedCustomers] = useState([]); // 메일 발송에 필요한 고객 정보 관리
  const [selectedCustomersms, setSelectedCustomersms] = useState([]); // sms 발송에 필요한 고객 정보 관리
  const [smsSend, setSmsSend] = useState({
    adminid: localStorage.getItem('msId'),
    msid: "01062292523",
    opensmsform: false,
    send_number: "",
    receive_number: { hpnumber: "", rname: "" },
    send_text: "",
    send_subject: "",
    send_type: "",
    customer_code: "",
    customer_name: "",
    order_code: "",
    reservationYn:"N",
    reservation_date:moment().format("YYYY-MM-DD"),
    reservation_time:moment().format("HH:mm:ss"),
  });





  const fetchData = async (filters = {}) => {
    setLoading(true);
    try {
      const response = await api.post('/ct/selectsearchCustomer', filters);
      const rowsWithId = response.data.map((row, index) => ({ id: row.gNum || index, ...row }));
      setData(rowsWithId);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchCartData = async () => {
    try {
      const response = await api.post('/ca/selectallAcart', { Adivi: 0 });
      setCartData(response.data);
    } catch (error) {
      console.error('Error fetching cart data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchCartData();
  }, []);

  useEffect(() => {
    console.log(selectedRows);
  }, selectedRows);
  

  const filterCartDataByDiviNum = (diviNum) => {
    return cartData.filter(cart => cart.diviNum === diviNum).sort((a, b) => a.sunWe - b.sunWe);
  };

  const handleRowClick = async (params) => {
    try {
      setSelectgNum(params.row.gNum);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectgNum(null);
  };

  const handleSave = async (updatedData) => {
    try {
      if (updatedData.gNum) {
        // 기존 데이터 수정
        await api.post('/ct/updateAcustomer', updatedData);
        setData((prevData) => prevData.map((row) => (row.gNum === updatedData.gNum ? updatedData : row)));
      } else {
        // 신규 데이터 입력
        await api.post('/ct/insertAcustomer', updatedData);
        fetchData(); // 전체 데이터를 다시 가져오기
      }
      handleClose();
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleDelete = async (dataToDelete) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까!!!!!!!!? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }
    
    try {
      const deleteResult = await api.post('/ct/deleteAcustomer', dataToDelete);
      
      //setData((prevData) => prevData.filter((row) => row.gNum !== dataToDelete.gNum));
      //window.alert(deleteResult);
      //console.log(deleteResult);
      if (typeof deleteResult.data.Result === 'string' && deleteResult.data.Result.length > 5) {
        window.alert("해당 고객으로 다른 정보들이 있습니다! 다른 정보들을 삭제후 고객삭제가 가능합니다");
      } else {
        fetchData(); // 전체 데이터를 다시 가져오기
        handleClose();
      }
      
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleAddNew = () => {
    setSelectgNum(1);
    setDialogOpen(true);
  };

  const handleSearch = () => {
    const filters = {
      searchName: searchText,
      dateType: 'regDate', // 예시: 가입일을 기준으로 검색
      startDate: startDate ? startDate.toISOString().split('T')[0] : null,
      endDate: endDate ? endDate.toISOString().split('T')[0] : null,
      cLevel : cLevel,
      cJaesan : cJaesan,
      cjuMin2 : cjuMin2,

    };
    fetchData(filters);
  };

  const handleAddSMS = () => {
    if (selectedRows.length === 0) {
    

      //체크된항목이 없다면 그냥 공백으로 넘겨서 발송가능토록 하게하자.
      setSmsSend({
        ...smsSend, // 기존의 input 객체를 복사한 뒤
        adminid: localStorage.getItem('msId'),
        opensmsform: true,
        send_number: "01062292523",
        receive_number:[],
        customer_code: [],
        customer_name: [],
      });
    }else{


    const selectedCustomerData = data
    .filter((row) => selectedRows.includes(row.gNum)) // 선택된 행 필터링
    .filter((row) => {
      const hp = row.cHp1 ? row.cHp1.replace(/[-.]/g, '') : ''; // '-' 또는 '.'을 제거하여 숫자만 남김
      return hp.startsWith('010') && hp.length === 11; // 010으로 시작하고, 숫자가 11개인지 확인
    })
    .map((row) => ({
      send_number: row.cHp1,
      send_text: "",
      reservation_date: "",
      reservation_time: "",
      reservationYn: "N",
      customer_code: row.cCode,
      customer_name: row.cName,
      order_code: row.gNum,
    }));
  
  // 핸드폰 리스트 추출
const receive_number = selectedCustomerData.map((row, index) => ({
  hpnumber: row.send_number,
  rname: row.customer_name,
  id: index + 1 // index를 사용하여 ID를 1부터 시작하게 설정
}));
  
  // 고객 코드 리스트 추출
  const customer_code = selectedCustomerData.map((row) => row.customer_code);
  
  // 고객 이름 리스트 추출
  const customer_name = selectedCustomerData.map((row) => row.customer_name);

      setSmsSend({
        ...smsSend, // 기존의 input 객체를 복사한 뒤
        adminid: localStorage.getItem('msId'),
        opensmsform: true,
        send_number: "01062292523",
        receive_number: receive_number,
        customer_code: customer_code,
        customer_name: customer_name,
      });


    }
   // setSelectedCustomersms(selectedCustomerData);
   // setSmsDialogOpen(true); // SMS 발송 다이얼로그 열기
  };

  const handleSmsDialogClose = () => {
    setSmsDialogOpen(false);
  };



  const handleAddMail = ()=>{
    if (selectedRows.length === 0) {
      alert("고객을 선택해 주세요."); // 체크된 항목이 없을 때 안내 메시지
      return;
    }

    const selectedCustomerData = data
  .filter((row) => selectedRows.includes(row.gNum) && row.cEmail1) // 이메일 주소가 있는 고객만 포함
  .map((row) => ({
    id: row.cCode,
    name: row.cName,
    email: row.cEmail1,
  }));

    setSelectedCustomers(selectedCustomerData);
    setMailDialogOpen(true);

    
  }


  const handleAddMailonelick = (data)=>{
    //console.log("data");
    //console.log(data);
    if (data === ''){
     // console.log("Dasdsad");
      return ;
    }

    // row 객체에서 필요한 정보를 추출
    const { id } = data;
    const { cName, cEmail1 } = data.row;

  
  const selectedCustomerData =[{ id: id,
    name: cName,
    email: cEmail1,}]
   // console.log(selectedCustomerData);
    setSelectedCustomers(selectedCustomerData);
    setMailDialogOpen(true);

    
  }



  const handleMailDialogClose = () => {
    setMailDialogOpen(false);
  };


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };


  const handleReset = () => {
    setSearchText('');
    setStartDate(null);
    setEndDate(null);
    setCLevel('');
    setCJaesan('');
    setCjuMin2('');
    fetchData();
  };

   const columns = [
    { field: 'gNum', headerName: '번호', width: 100, hide: true },
    { field: 'cDaeCode', headerName: '대코드', width: 150, hide: true },
    { field: 'cCode', headerName: '코드', width: 150, hide: true },
    { field: 'cName', headerName: '이름', width: 150, renderCell: (params) => (
      <div
        onClick={(event) => {
          event.stopPropagation(); // 클릭 시 행 선택 방지
          handleRowClick(params, event); // 대신 정보창 등을 띄우는 로직 실행
        }}
      >
        {params.value}
      </div>
    ) 
  },
    { field: 'cLevel', headerName: '등급', width: 150, renderCell: (params) => (
      <div
        onClick={(event) => {
          event.stopPropagation(); // 클릭 시 행 선택 방지
          handleRowClick(params, event); // 대신 정보창 등을 띄우는 로직 실행
        }}
      >
        {params.value}
      </div>
    )  },
    { field: 'cHp1', headerName: '휴대폰1', width: 150, renderCell: (params) => (
      <div
        onClick={(event) => {
          event.stopPropagation(); // 클릭 시 행 선택 방지
          // 다른 필드 값 가져오기
        const customerName = params.row.cName; 
        const customerCode = params.row.cCode;
            console.log(params);
          openSmsSend({
            opensmsform:true,
            receive_number: [{ hpnumber: params.value, id:1 }],
            send_number: "01062292523",
            customer_name: [customerName],
            customer_code: [customerCode],
            order_code: "",
            reservationYn:'N',
            send_text:'',
          });
      
        }}
      >
        {params.value}
      </div>
    )  },
    { field: 'cEmail1', headerName: '이메일1', width: 200, renderCell: (params) => (
      <div
        onClick={(event) => {
          event.stopPropagation(); // 클릭 시 행 선택 방지
          handleAddMailonelick(params, event); // 대신 정보창 등을 띄우는 로직 실행
        }}
      >
        {params.value}
      </div>
    )  },



  

    { field: 'cJaesan', headerName: '보유자산', width: 200, renderCell: (params) => (
      <div
        onClick={(event) => {
          event.stopPropagation(); // 클릭 시 행 선택 방지
          handleRowClick(params, event); // 대신 정보창 등을 띄우는 로직 실행
        }}
      >
        {params.value}
      </div>
    )  },
    { field: 'regDate', headerName: '등록일', width: 150, renderCell: (params) => (
      <div
        onClick={(event) => {
          event.stopPropagation(); // 클릭 시 행 선택 방지
          handleRowClick(params, event); // 대신 정보창 등을 띄우는 로직 실행
        }}
      >
        {params.value}
      </div>
    )  },
    { field: 'modifyDate', headerName: '수정일', width: 150, renderCell: (params) => (
      <div
        onClick={(event) => {
          event.stopPropagation(); // 클릭 시 행 선택 방지
          handleRowClick(params, event); // 대신 정보창 등을 띄우는 로직 실행
        }}
      >
        {params.value}
      </div>
    )  },
  ];


  const genderCodes = filterCartDataByDiviNum(6); // 성별의 Adivi 값 사용
  const gradeCodes = filterCartDataByDiviNum(1); // 고객등급의 Adivi 값 사용
  const jasaCodes = filterCartDataByDiviNum(5); // 고객등급의 Adivi 값 사용

  const openSmsSend = (dataaa) => {
    setSmsSend({
      ...smsSend, // 기존의 input 객체를 복사한 뒤
      opensmsform: dataaa.opensmsform,
      send_number: dataaa.send_number,
      receive_number: dataaa.receive_number,
      send_text: dataaa.send_text,
      send_subject: dataaa.send_subject,
      send_type: dataaa.send_type,
      customer_code: dataaa.customer_code,
      customer_name: dataaa.customer_name,
      order_code: dataaa.order_code,
      reservationYn:dataaa.reservationYn,
      viewselecttype:false,
    });
  };


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw' }}>
      
      <Box sx={{ display: 'flex', alignItems: 'center', margin: '16px' }}>
      <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={1.5}>
        <TextField
          label="검색"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="outlined"
          sx={{ marginRight: '16px' }}
          onKeyPress={handleKeyPress} // 엔터 키를 감지
        />
        </Grid>
        <Grid item xs={12} sm={1}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="가입일 시작"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} sx={{ marginRight: '16px' }} />}
            inputFormat="yyyy-MM-dd"
          />
          </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={1}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="가입일 종료"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} sx={{ marginRight: '16px' }} />}
            inputFormat="yyyy-MM-dd"
          />
          </LocalizationProvider>
          </Grid>
           <Grid item xs={12} sm={1}>
            <FormControl fullWidth margin="dense">
              <InputLabel>고객등급</InputLabel>
              <Select
                name="cLevel"
                value={cLevel || ''}
                onChange={(e) => setCLevel(e.target.value)}
                fullWidth
              >
                <MenuItem key='' value=''>-고객등급-</MenuItem>
                {gradeCodes.map((code) => (
                  <MenuItem key={code.cartName} value={code.cartName}>
                    {code.cartName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
            <FormControl fullWidth margin="dense">
              <InputLabel>보유자산</InputLabel>
              <Select
                name="cJaesan"
                value={cJaesan || ''}
                onChange={(e) => setCJaesan(e.target.value)}
                fullWidth
              >
                <MenuItem key='' value=''>-보유자산-</MenuItem>
                {jasaCodes.map((code) => (
                  <MenuItem key={code.cartName} value={code.cartName}>
                    {code.cartName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={0.5}>
            <FormControl fullWidth margin="dense">
              <InputLabel>성별</InputLabel>
              <Select
                name="cjuMin2"
                value={cjuMin2 || ''}
                onChange={(e) => setCjuMin2(e.target.value)}
                fullWidth
              >
                <MenuItem key='' value=''>-성별-</MenuItem>
                {genderCodes.map((code) => (
                  <MenuItem key={code.cartName} value={code.cartName}>
                    {code.cartName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={1}>
        <Button variant="contained" color="primary" onClick={handleSearch} sx={{ marginRight: '16px' }} fullWidth>
          검색
        </Button>
        </Grid>
        <Grid item xs={12} sm={1}>
        <Button variant="contained" color="secondary" onClick={handleReset} fullWidth >
          리셋
        </Button>
        </Grid>
        <Grid item xs={12} sm={1}>
         <Button variant="contained" color="error" onClick={handleAddNew} fullWidth>
          신규
        </Button>
        </Grid>
        <Grid item xs={12} sm={1}>
         <Button variant="contained" color="primary" onClick={handleAddMail} fullWidth>
       메일
        </Button>
        </Grid>
        <Grid item xs={12} sm={1}>
         <Button variant="contained" color="primary" onClick={handleAddSMS} fullWidth>
         문자
        </Button>
        </Grid>
        </Grid>
      </Box>
      <Box sx={{ flexGrow: 1, margin: '0 16px 16px 16px' }}>
        <DataGrid
          rows={data}
          checkboxSelection
          columns={columns}
          pageSize={10}
          loading={loading}
          getRowId={(row) => row.gNum}
         
          onRowSelectionModelChange={(newSelection) => {
           // console.log("New selection:", newSelection); // 콘솔 로그로 확인
            setSelectedRows(newSelection);
          }} // 선택된 행을 상태로 저장
          sx={{ height: '80vh' }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
          initialState={{
            columns: {
              columnVisibilityModel: {
                gNum: false,
                cDaeCode: false, // 숨기려는 열의 필드를 false로 설정
                cCode: false, // 숨기려는 열의 필드를 false로 설정
              },
            },
          }}
          pageSizeOptions={[10,25, 50, 100]} // 페이지당 행 수 옵션을 추가
        />
      </Box>
      {selectgNum && (
        <CustomerModifyForm2
          open={dialogOpen}
          handleClose={handleClose}
          handleSave={handleSave}
          handleDelete={handleDelete}
          filterCartDataByDiviNum={filterCartDataByDiviNum}
          gNum={selectgNum}
          selectedCustomersms={selectedCustomersms}
          setSelectedCustomersms={setSelectedCustomersms}
          smsSendRef = {smsSend}
          onClose={handleMailDialogClose}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          cartselected={"0"}
          mailDialogOpen={mailDialogOpen}
          handleMailDialogClose={handleMailDialogClose}
          setMailDialogOpen={setMailDialogOpen}
        />
      )}

       {/* SMS 발송 폼 */}
       {smsSend.opensmsform && (
        <SmsForm
          open={smsSend.opensmsform}
          onClose={handleSmsDialogClose}
          selectedCustomersms={selectedCustomersms}
          setSelectedCustomersms={setSelectedCustomersms}
          smsSendRef = {smsSend}
        />
      )}



        {/* 메일 발송 폼 */}
      {mailDialogOpen && (
        <MailsendForm
          open={mailDialogOpen}
          onClose={handleMailDialogClose}
          selectedCustomers={selectedCustomers}
          setSelectedCustomers={setSelectedCustomers}
          cartselected={"0"}
        />
      )}
    </Box>
  );
};

export default CustomerManagement2;
