import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../features/login/loginSlice';


const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // 로컬 스토리지에서 토큰 제거
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    
        // 상태 업데이트 및 로그인 페이지로 리디렉션
        dispatch(logout());
        navigate('/login');
      }, [navigate, dispatch]);

  return null;
};

export default Logout;