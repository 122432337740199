import { useState, useEffect } from 'react';
import api from '../api';

const useCustomerData2 = (gNum) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [familyData, setFamilyData] = useState([]);
  const [moneyData, setMoneyData] = useState([]);
  const [yugaPaperData, setYugaPaperData] = useState([]);
  const [budongsanData, setBudongsanData] = useState([]);
  const [caemuData, setCaemuData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [serviceData,setServiceData] = useState([]); 
  const [companyjusicData,setCompanyjusicData] = useState([]); 
  const [emailsendData,setEmailsendData] = useState([]); 

  const [loading, setLoading] = useState(true);

   const fetchCustomerData = async (gNum) => {
    setLoading(true);
    try {
      const response = await api.post('/ct/getCustomer', { gNum:gNum });
      const customerData = response.data[0][0]; // 첫 번째 테이블의 첫 번째 레코드 (기본 고객 정보)
      const familyData = response.data[1]; // 두 번째 테이블의 레코드들 (가족 정보)
      const moneyData = response.data[2]; // 세 번째 테이블의 레코드들 (가족의 돈 정보)
      const yugaPaperData = response.data[3]; // 네 번째 테이블의 레코드들 (가족의 유가증권 정보)
      const budongsanData = response.data[4]; // 다섯 번째 테이블의 레코드들 (가족의 부동산 정보)
      const caemuData = response.data[5]; // 여섯 번째 테이블의 레코드들 (가족의 부채 정보)
      const companyData = response.data[6]; // 일곱 번째 테이블의 레코드들 (가족의 회사 정보)
      const serviceData = response.data[7]; // 여덟 번째 테이블의 레코드들 (용역테이블)
      const companyjusicData = response.data[8];  //아홉번째 테이블의 레코드들(회사주식테이블)
      const emailsendData = response.data[9];  //열번째 테이블의 레코드들(회사주식테이블)

      setSelectedRow(customerData);
      setFamilyData(familyData);
      setMoneyData(moneyData);
      setYugaPaperData(yugaPaperData);
      setBudongsanData(budongsanData);
      setCaemuData(caemuData);
      setCompanyData(companyData);
      setServiceData(serviceData);
      setCompanyjusicData(companyjusicData);
      setEmailsendData(emailsendData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching customer data:', error);
      setLoading(false);
    }
  };


  const handleFamilyUpdate = (updatedFamily) => {
    setFamilyData(updatedFamily);
  };

  const handleServiceUpdate = (updatedService) => {
    // 서비스 데이터를 업데이트하는 함수 추가
    setServiceData(updatedService)
  };


  const handleMoneyUpdate = (updatedMoney) => {
    // 서비스 데이터를 업데이트하는 함수 추가
    setMoneyData(updatedMoney)
  };

  const handleYugaUpdate = (updatedYuga) => {
    // 서비스 데이터를 업데이트하는 함수 추가
    setYugaPaperData(updatedYuga)
  };

  const handleBudongsanUpdate = (updatedBudongsan) => {
    // 서비스 데이터를 업데이트하는 함수 추가
    setBudongsanData(updatedBudongsan)
  };

  const handleCaemuUpdate = (updatedCaemu) => {
    // 서비스 데이터를 업데이트하는 함수 추가
    setCaemuData(updatedCaemu)
  };

   const handleCompanyUpdate = (updatedCompany) => {
    // 서비스 데이터를 업데이트하는 함수 추가
    setCompanyData(updatedCompany)
  };

  const handleCompanyjusicUpdate = (updatedCompanyjusic) => {
    // 서비스 데이터를 업데이트하는 함수 추가
    setCompanyjusicData(updatedCompanyjusic)
  };

  const handleEmailsendDataUpdate = (updatedEmailsend) => {
    // 서비스 데이터를 업데이트하는 함수 추가
    setEmailsendData(updatedEmailsend)
  };

  const handleReset = () => {
    setSelectedRow(null);
    setFamilyData([]);
    setMoneyData([]);
    setYugaPaperData([]);
    setBudongsanData([]);
    setCaemuData([]);
    setCompanyData([]);
    setServiceData([]);
    setCompanyjusicData([]);
    setEmailsendData([]);
    
  };

  


  useEffect(() => {
    if (gNum) {
      fetchCustomerData(gNum);
    }
  }, [gNum]);




return {
    data:selectedRow, 
    familyData, 
    serviceData,
    handleFamilyUpdate,
    handleServiceUpdate,
    handleMoneyUpdate,
    moneyData, 
    yugaPaperData, 
    handleYugaUpdate,
    budongsanData, 
    handleBudongsanUpdate,
    caemuData, 
    handleCaemuUpdate,
    companyData, 
    handleCompanyUpdate,
    companyjusicData,
    handleCompanyjusicUpdate,
    loading,
    handleReset,
    fetchCustomerData,
    emailsendData,
    handleEmailsendDataUpdate
  };
};

export default useCustomerData2;