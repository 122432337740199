import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid,Select,MenuItem,FormControl,InputLabel } from '@mui/material';
import { CustomTextField, CustomDateField } from './CustomTextField';


const BudongsanForm = ({ open, handleClose, data, handleSave, handleDelete,filterCartDataByDiviNum,familyData }) => {
 const [formData, setFormData] = useState(data || {});


 const jinState = filterCartDataByDiviNum(11); // 부동산 종류


  useEffect(() => {
    if (!data) {
      // 새로 추가할 때 기본적으로 본인을 선택
      const primaryMember = familyData.find(member => member.cDaeCode === member.cCode);
      setFormData(primaryMember => ({
        ...primaryMember,
        cCode: primaryMember.cDaeCode,
      }));
    } else {
      // 수정 시 기존 데이터를 그대로 사용
      setFormData(data);
    }
  }, [data, familyData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const saveChanges = () => {
    handleSave(formData);
  };

  const deleteChanges = () => {
    handleDelete(formData);
  };

  const isUpdate = !!formData.gNum;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{isUpdate ? '부동산 수정' : '부동산 추가'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Select
                name="cCode"
                value={formData.cCode || ''}
                onChange={handleChange}
                fullWidth
              >
                {familyData.map((member) => (
                  <MenuItem key={member.cCode} value={member.cCode}>
                    {member.cName}
                  </MenuItem>
                ))}
              </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="dense">
              <InputLabel>종류</InputLabel>
              <Select
               label="종류"
                name="jongRu"
                value={formData.jongRu || ''}
                onChange={handleChange}
                fullWidth
              >
                {
                  jinState.map((code) => (
                    <MenuItem key={code.cartName} value={code.cartName}>
                      {code.cartName}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>

           
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="changeDate"
              label="변동일"
              type="text"
              fullWidth
              value={formData.changeDate || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="saYu"
              label="사유"
              type="text"
              fullWidth
              value={formData.saYu || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTextField
              margin="dense"
              name="plusSu"
              label="변동금액(증)"
              type="number"
              fullWidth
              value={formData.plusSu || ''}
              onChange={handleChange}
            />
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <CustomTextField
              margin="dense"
              name="minusSu"
              label="변동금액(감)"
              type="number"
              fullWidth
              value={formData.minusSu || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="addRess"
              label="주소지"
              type="text"
              fullWidth
              value={formData.addRess || ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="sangDaebang"
              label="상대방"
              type="text"
              fullWidth
              value={formData.sangDaebang || ''}
              onChange={handleChange}
            />
          </Grid>
         
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          취소
        </Button>
        {isUpdate && (
          <Button onClick={deleteChanges} color="primary">
            삭제
          </Button>
        )}
        <Button onClick={saveChanges} color="primary">
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BudongsanForm;
