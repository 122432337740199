import React from 'react';

function Contact() {
  return (
    <div>
      <h1>환경설정</h1>
      <p>카테고리설정</p>
    </div>
  );
}

export default Contact;

