import React, { useState, useEffect } from 'react';
import { Typography, Button, Select, MenuItem, FormControl, InputLabel, TextField, Grid, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import api from '../api'; // Axios 인스턴스 가져오기
import '../style.css'; // CSS 파일 임포트

const Mailfilelist = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0); // 초기값 0 설정
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileTitle, setFileTitle] = useState(''); // 파일 타이틀
  const [file, setFile] = useState(null); // 업로드할 파일

  const fetchCategories = async () => {
    try {
      const response = await api.post('/ca/selectallAcart', { Adivi: 15 }); // 대분류 15번의 중분류 카테고리 가져오기
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchFiles = async (categoryGNum) => {
    setLoading(true);
    try {
      const response = await api.post('/cf/selectallAcartfilelist', { cartgNum: categoryGNum });
      setFiles(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching files:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchFiles(0);
  }, []);

  useEffect(() => {
      fetchFiles(selectedCategory);
  }, [selectedCategory]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file || !fileTitle) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', fileTitle);
    formData.append('cartgNum', selectedCategory);

    try {
      const response = await api.post('/cf/insertAcartfilelist', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchFiles(selectedCategory); // 업로드 후 파일 목록 갱신
      setFile(null);
      setFileTitle('');
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('파일 업로드 중 오류가 발생했습니다.');
    }
  };



  const handleDownload = async(filepath) => {
    try {
      const filename = filepath.split('\\').pop(); // 서버의 경로에서 파일명만 추출 (Windows 경로의 경우)
      const downloadUrl = `/cf/download/${filename}`; // 서버의 다운로드 엔드포인트와 연결

      // 서버로부터 파일 데이터를 받아옴
      const response = await api.get(downloadUrl, {
          responseType: 'blob', // 바이너리 데이터를 받기 위해 'blob'을 사용
      });

      // 받은 데이터를 Blob으로 변환
      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Blob 데이터를 사용해 다운로드 링크 생성
      const link = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', filename); // 다운로드할 파일 이름 지정
      document.body.appendChild(link);
      link.click();

      // 메모리 해제
      link.remove();
      window.URL.revokeObjectURL(url);
  } catch (error) {
      console.error('Error downloading the file:', error);
  }
};

const handleDelete = async (gNum, filepath) => {
  try {
      const filename = filepath.split('\\').pop(); // 파일명 추출 (Windows 경로의 경우)
      await api.post('/cf/deleteAcartfilelist', { gNum, filename }); // 파일명과 gNum을 서버로 전달

      fetchFiles(selectedCategory); // 업로드 후 파일 목록 갱신
  } catch (error) {
      console.error('Error deleting file:', error);
  }
};


  const columns = [
    { field: 'gNum', headerName: '번호', width: 150 },
    { field: 'filename', headerName: '파일 타이틀', width: 300 },
    {
      field: 'download',
      headerName: '다운로드',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDownload(params.row.filepath)} // params.row.filepath로 접근
        >
          다운로드
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: '삭제',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleDelete(params.row.gNum, params.row.filepath)} // params.row.gNum과 params.row.filepath로 접근
        >
          삭제
        </Button>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', padding: '16px' }}>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} md={3}>
        <Typography variant="h6" gutterBottom>
          메일파일관리
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl fullWidth>
          <InputLabel>중분류 선택</InputLabel>
          <Select
           label="중분류 선택"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <MenuItem value={0}>중분류 선택</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.gNum} value={category.gNum}>
                {category.cartName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          label="파일 타이틀"
          value={fileTitle}
          onChange={(e) => setFileTitle(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <TextField type="file" onChange={handleFileChange} fullWidth />
      </Grid>
      <Grid item xs={12} md={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!file || !selectedCategory || !fileTitle}
          fullWidth
        >
          업로드
        </Button>
      </Grid>
    </Grid>

    <div style={{ flexGrow: 1, marginTop: '16px' }}>
        <DataGrid
          rows={files}
          columns={columns}
          pageSize={5}
          loading={loading}
          getRowId={(row) => row.gNum}
          style={{ height: '400px' }}
        />
      </div>
  </div>
  );
};

export default Mailfilelist;
