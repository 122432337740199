import React, { useState, useEffect } from 'react';
import { Typography, Button, Select, MenuItem, FormControl, InputLabel, TextField, Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import api from '../api';

const MailContentsManager = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [mailContents, setMailContents] = useState([]);
  const [mailTitle, setMailTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedMail, setSelectedMail] = useState(null);
  const [loading, setLoading] = useState(false);



  const fetchCategories = async () => {
    try {
      const response = await api.post('/ca/selectallAcart', { Adivi: 15 }); // 대분류 15번의 중분류 카테고리 가져오기
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };



  const fetchContents = async (category) => {
    setLoading(true);
    try {

      const response = await api.post('/ms/selectallAmailsendContents', { cartNum: category });
      setMailContents(response.data);

      if (category == '0'){
        setMailTitle('');
        setContent('');
      }
    } catch (error) {
      console.error('Error fetching mail contents:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
  
    fetchCategories();
    fetchContents(0); // 초기 로드 시 모든 메일 내용을 가져옴
  }, []);


  const handleSave = async () => {
    try {
      if (selectedMail) {
        // 업데이트
        await api.post('/ms/updateAmailsendContents', {
          gNum: selectedMail.gNum,
          mailsendTitle: mailTitle,
          mailsendContents: content,
          cartNum: selectedCategory,
        });

        // 업데이트된 항목을 리스트에서 수정
        setMailContents((prevContents) =>
          prevContents.map((mail) =>
            mail.gNum === selectedMail.gNum
              ? { ...mail, mailsendTitle: mailTitle, mailsendContents: content, cartNum: selectedCategory }
              : mail
          )
        );
      } else {
        // 새로 추가
        const response = await api.post('/ms/insertAmailsendContents', {
         gNum: 0,
          mailsendTitle: mailTitle,
          mailsendContents: content,
          cartNum: selectedCategory,
        });

        fetchContents(0); // 초기 로드 시 모든 메일 내용을 가져옴
      }
      handleReset();
    } catch (error) {
      console.error('Error saving mail content:', error);
    }
  };


  const handleDelete = async (gNum) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }
    
    try {
      await api.post('/ms/deleteAmailsendContents', { gNum });

      // 삭제된 항목을 리스트에서 제거
      setMailContents((prevContents) =>
        prevContents.filter((mail) => mail.gNum !== gNum)
      );

      // 폼 초기화
      handleReset();
    } catch (error) {
      console.error('Error deleting mail content:', error);
    }
  };


  const handleSelectMail = (mail) => {
    setSelectedMail(mail);
    setMailTitle(mail.mailsendTitle);
    setContent(mail.mailsendContents);
    setSelectedCategory(mail.cartNum);
  };

  const handleReset = () => {
    setSelectedMail(null);
    setMailTitle('');
    setContent('');
    setSelectedCategory('');
  };

  useEffect(() => {
    fetchContents(selectedCategory);
  }, [selectedCategory]);

  const columns = [
    { field: 'gNum', headerName: '번호', width: 100 },
    { field: 'mailsendTitle', headerName: '메일 타이틀', width: 300 },
    {
      field: 'actions',
      headerName: '액션',
      width: 400,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSelectMail(params.row)}
            style={{ marginRight: '8px' }}
          >
            수정
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleDelete(params.row.gNum)}
          >
            삭제
          </Button>
        </>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', padding: '16px' }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            메일 내용 관리
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>중분류 선택</InputLabel>
            <Select
             label="중분류 선택"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <MenuItem value={0}>전체</MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.gNum} value={category.gNum}>
                  {category.cartName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="메일 타이틀"
            value={mailTitle}
            onChange={(e) => setMailTitle(e.target.value)}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: '16px' }}>
        <Grid item xs={12}>
          <ReactQuill value={content} onChange={setContent} />
        </Grid>
        <Grid item xs={12} style={{ marginTop: '16px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={!mailTitle || !content || !selectedCategory}
            fullWidth
          >
            저장
          </Button>
        </Grid>
      </Grid>

      <div style={{ flexGrow: 1, marginTop: '16px' }}>
        <DataGrid
          rows={mailContents}
          columns={columns}
          pageSize={5}
          loading={loading}
          getRowId={(row) => row.gNum}
          style={{ height: '400px' }}
        />
      </div>
    </div>
  );
};

export default MailContentsManager;
