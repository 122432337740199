import React, { useState, useEffect } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import api from '../api'; // Axios 인스턴스 가져오기
import EmployeeEditForm from './EmployeeEditForm';
import AlertDialog from './AlertDialog'; // 알림 모달 컴포넌트 가져오기

const EmployeeManagement = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false); // 알림 모달 상태
  const [alertMessage, setAlertMessage] = useState(''); // 알림 메시지 상태

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.post('/sawon/selectallAmember');
      const rowsWithId = response.data.map((row, index) => ({ id: row.gNum || index, ...row }));
      setData(rowsWithId);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertMessage('');
  };

  const handleSave = async (updatedData) => {
    try {
      if (updatedData.gNum) {
        // 기존 데이터 수정
        await api.post('/sawon/updateAmember', updatedData);
        setData((prevData) => prevData.map((row) => (row.gNum === updatedData.gNum ? updatedData : row)));
      } else {
        // 신규 데이터 입력
        const response = await api.post('/sawon/insertAmember', updatedData);
        if (response?.data?.message === 'Y') {
          fetchData(); // 전체 데이터를 다시 가져오기
          setAlertMessage('성공적으로 입력하였습니다');
          setAlertOpen(true);
        } else {
          throw new Error(response.data.message);
        }
      }
      handleClose();
    } catch (error) {
      console.error('Error saving data:', error);
      setAlertMessage(error.response?.data?.message || '입력 실패');
      setAlertOpen(true);
    }
  };

  const handleDelete = async (dataToDelete) => {
    const confirmCancel = window.confirm('정말로 삭제하시겠습니까? 되돌릴수 없습니다');

    if (!confirmCancel) {
      // 사용자가 "취소"를 선택한 경우 함수 실행 중단
      return;
    }

    
    try {
      await api.post('/sawon/deleteAmember', dataToDelete);
      setData((prevData) => prevData.filter((row) => row.gNum !== dataToDelete.gNum));
      handleClose();
    } catch (error) {
      console.error('Error deleting data:', error);
      setAlertMessage('삭제 실패');
      setAlertOpen(true);
    }
  };

  const handleAddNew = () => {
    setSelectedRow({ gNum: '', msId: '', msPass: '', msName: '', msHp: '', msTel: '', msEmail: '', regDate: '', modifyDate: '', lastLogindate: '', customerReadYn: false, customerDelYn: false, customerModifyYn: false, customerWriteYn: false, msLevel: 0 });
    setDialogOpen(true);
  };

  const columns = [
    { field: 'gNum', headerName: '번호', width: 60 },
    { field: 'msId', headerName: '아이디', width: 150 },
    { field: 'msName', headerName: '이름', width: 150 },
    { field: 'msHp', headerName: '휴대폰 번호', width: 150 },
    { field: 'msEmail', headerName: '이메일', width: 200 },
    { field: 'regDate', headerName: '등록일', width: 100 },
    { field: 'modifyDate', headerName: '수정일', width: 100 },
    { field: 'lastLogindate', headerName: '마지막 로그인', width: 100 },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
    <div style={{ display: 'flex', alignItems: 'center', margin: '16px' }}>
      <Typography variant="h4" style={{ flexGrow: 1 }}>
        사원 관리
      </Typography>
      <Button variant="contained" color="primary" onClick={handleAddNew}>
        신규 입력
      </Button>
    </div>
    <div style={{ flexGrow: 1, margin: '0 16px 16px 16px' }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={10}
        loading={loading}
        getRowId={(row) => row.gNum}
        onRowClick={handleRowClick}
        style={{ height: '100%' }} // Adjust the height of the DataGrid
      />
    </div>
    {selectedRow && (
      <EmployeeEditForm
        open={dialogOpen}
        handleClose={handleClose}
        data={selectedRow}
        handleSave={handleSave}
        handleDelete={handleDelete}
      />
    )}
  </div>
);
};

export default EmployeeManagement;
